import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'bulkButton',
    'rowCheckbox',
    'rowCheckboxAll',
    'rowsCheckedSummary',
    'rowsCheckedCounter',
    'rowsCheckedList',
    'rowsCheckedEmpty',
    'summaryCollapseTrigger',
    'setListSelect',
    'setListName',
    'setListNameInput',
    'setListNameWarning',
    'addToSetListButton',
    'addToSetListForm'
  ]

  static values = {
    rows: Number,
    rowsChecked: Array,
    saveRowsCheckedState: Boolean,
    setListsNames: Array
  }

  initialize() {
    // Load the checked rows from the local storage
    this.loadRowsChecked()
  }

  /**
   * rowsChecked change callback - triggered when rowsCheckedValue changes
   */
  rowsCheckedValueChanged() {
    // Enable bulk buttons if at least one row is checked, disable otherwise
    this.updateBulkButtons()
    
    // If all rows are checked, check the "Select all" checkbox, uncheck otherwise
    this.updateSelectAllCheckbox()

    // Save the checked rows to the local storage
    this.saveRowsChecked()

    // Update the summary of selected rows
    this.updateRowsCheckedSummary()

    // Show/hide trigger based on checked rows
    if (this.hasSummaryCollapseTriggerTarget) {
      if (this.rowsCheckedValue.length > 0) {
        this.summaryCollapseTriggerTarget.classList.remove('d-none')
        this.rowsCheckedEmptyTarget.classList.add('d-none')
      } else {
        this.summaryCollapseTriggerTarget.classList.add('d-none')
        this.rowsCheckedEmptyTarget.classList.remove('d-none')
      }
    }
  }

  /**
   * triggered when a row checkbox is toggled
   */
  rowCheckboxToggle(event) {
    this.updateRowsChecked(event.target)
    this.toggleCheckboxStyle(event.target)
  }

  /**
   * triggered when the "Select all" checkbox is toggled
   */
  toggleAllRowCheckboxes(event) {
    // Check/uncheck all row checkboxes
    this.rowCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = event.target.checked
      this.updateRowsChecked(checkbox)
      this.toggleCheckboxStyle(checkbox)
    })
  }

  /**
   * Add/remove the row to/from the rowsChecked array
   */
  updateRowsChecked(checkbox) {
    const id = parseInt(checkbox.value)
    const title = checkbox.dataset.songTitle
    const artist = checkbox.dataset.songArtist

    const songObj = { id, title, artist }

    if (checkbox.checked) {
      this.addSong(songObj)
    } else {
      this.removeSong(songObj)
    }
  }
  /**
   * Toggle style class on card-footer based on checkbox state
   */
  toggleCheckboxStyle(checkbox) {
    const cardFooter = checkbox.closest('.checkbox-table-wrapper')
    if (cardFooter) {
      if (checkbox.checked) {
        cardFooter.classList.add('selected')
      } else {
        cardFooter.classList.remove('selected')
      }
    }
  }


  /**
   * Add a JSON object with song id, title and artist to the rowsChecked array
   */
  addSong(songObj) {
    if (!this.rowsCheckedValue.some(obj => obj.id === songObj.id)) {
      this.rowsCheckedValue = [...this.rowsCheckedValue, songObj]
    }
  }

  /**
   * Remove the JSON object with song id from the rowsChecked array
   */
  removeSong(songObj) {
    this.rowsCheckedValue = this.rowsCheckedValue.filter(obj => obj.id !== songObj.id)
  }

  /**
   * Enable bulk buttons if at least one row is checked, disable otherwise
   */
  updateBulkButtons() {
    if (this.rowsCheckedValue.length > 0) {
      this.bulkButtonTargets.forEach((button) => { button.classList.remove('disabled') })
    } else {
      this.bulkButtonTargets.forEach((button) => { button.classList.add('disabled') })
    }
  }

  /**
   * If all rows are checked, check the "Select all" checkbox, uncheck otherwise
   */
  updateSelectAllCheckbox() {
    if (!this.hasRowCheckboxAllTarget) return

    this.rowCheckboxAllTarget.checked = this.rowsCheckedValue.length === this.rowsValue
  }

  /**
   * Load the checked rows from the local storage
   */
  loadRowsChecked() {
    if (!this.saveRowsCheckedStateValue) return

    const rowsChecked = JSON.parse(localStorage.getItem('rowsChecked'))
    const selectedSetList = localStorage.getItem('selectedSetList')
    const savedSetListName = localStorage.getItem('newSetListName') 

    if (rowsChecked) {
      this.rowsCheckedValue = rowsChecked

      // Check the checkboxes of the rows that are in the rowsChecked array
      this.rowCheckboxTargets.forEach((checkbox) => {
        checkbox.checked = this.rowsCheckedValue.some(obj => obj.id === parseInt(checkbox.value))
        this.toggleCheckboxStyle(checkbox)
      })

      // Check the "Select all" checkbox if all rows are checked
      this.updateSelectAllCheckbox()
      // Show/hide trigger and update collapse based on checked rows
      if (rowsChecked.length > 0) {
        if (this.hasSummaryCollapseTriggerTarget) {
          this.summaryCollapseTriggerTarget.classList.remove('d-none')
        }
        this.rowsCheckedSummaryTarget.classList.add('show')
        // Add class to SongsBlock
        const songsBlock = document.querySelector('[data-set-list-menu-target="SongsBlock"]')
        if (songsBlock) {
          songsBlock.classList.add('col-lg-7','col-xl-8')
        }
      } else {
        if (this.hasSummaryCollapseTriggerTarget) {
          this.summaryCollapseTriggerTarget.classList.add('d-none')
        }
        if (this.hasRowsCheckedEmptyTarget) {
          this.rowsCheckedEmptyTarget.classList.remove('d-none');
        }
      }
    }
    // Set the selected set list if it exists
    if (selectedSetList) {
      this.setListSelectTarget.value = selectedSetList
      // Trigger the change event to update any dependent UI
      this.setListSelectChanged()
    }
    // Load saved set list name if it exists
    if (savedSetListName && this.hasSetListNameInputTarget) {
      this.setListNameInputTarget.value = savedSetListName
      // Trigger the change event to update any dependent UI
      this.setListNameInputChanged()
    }
  }

  /**
   * Save both checked rows and selected set list to localStorage
   */
  saveRowsChecked() {
    if (!this.saveRowsCheckedStateValue) return

    localStorage.setItem('rowsChecked', JSON.stringify(this.rowsCheckedValue))
  }

  /**
   * Update the summary of selected rows
   */
  updateRowsCheckedSummary() {
    if (!this.hasRowsCheckedSummaryTarget) return

    // Update the span with the number of selected rows
    if ( this.rowsCheckedValue.length == 1){
      this.rowsCheckedCounterTarget.innerHTML = `<strong>${this.rowsCheckedValue.length}</strong> song selected`
    } else {
      this.rowsCheckedCounterTarget.innerHTML = `<strong>${this.rowsCheckedValue.length}</strong> songs selected`
    }
    this.rowsCheckedListTarget.innerHTML = '' // Clear the current list
    if ( this.rowsCheckedValue.length > 0){
      this.rowsCheckedValue.forEach(songObj => {
        const li = document.createElement('li')

        // Set text content to the song title / artist
        li.textContent = `${songObj.title}, by ${songObj.artist}`

        this.rowsCheckedListTarget.appendChild(li)
      });
    } else {
      this.rowsCheckedEmptyTarget.classList.remove('d-none');
    }
    

    // Update the "Add to set list" button status
    this.updateAddToSetListButtonStatus()
  }

  updateAddToSetListButtonStatus() {
    // If no rows are checked, disable the "Add to set list" button and return
    if (this.rowsCheckedValue.length === 0) {
      this.addToSetListButtonTarget.setAttribute('disabled', 'disabled')

      // Handle set list name input visibility based on select value
      if (this.setListSelectTarget.value) {
        this.setListNameInputTarget.setAttribute('disabled', 'disabled')
        this.setListNameTarget.classList.add('d-none')
        if (this.hasSetListNameWarningTarget) {
          this.setListNameWarningTarget.classList.add('d-none')
        }
      } else {
        this.setListNameInputTarget.removeAttribute('disabled')
        this.setListNameTarget.classList.remove('d-none')

        // Still validate the name if there's a value
        if (this.setListNameInputTarget.value && this.setListNameExists(this.setListNameInputTarget.value)) {
          if (this.hasSetListNameWarningTarget) {
            this.setListNameWarningTarget.textContent = 'A set list with this name already exists'
            this.setListNameWarningTarget.classList.remove('d-none')
          }
        } else {
          if (this.hasSetListNameWarningTarget) {
            this.setListNameWarningTarget.classList.add('d-none')
          }
        }
      }
      return
    }

    // If a set list is selected, enable the "Add to set list" button and disable the set list name input
    if (this.setListSelectTarget.value) {
      this.addToSetListButtonTarget.removeAttribute('disabled')
      this.setListNameInputTarget.setAttribute('disabled', 'disabled')
      this.setListNameTarget.classList.add('d-none')
      if (this.hasSetListNameWarningTarget) {
        this.setListNameWarningTarget.classList.add('d-none')
      }
      return
    }

    // Otherwise, enable the set list name input
    this.setListNameInputTarget.removeAttribute('disabled')
    this.setListNameTarget.classList.remove('d-none')

    // If the set list name input is not empty
    if (this.setListNameInputTarget.value) {
      // Check if name already exists
      if (this.setListNameExists(this.setListNameInputTarget.value)) {
        this.addToSetListButtonTarget.setAttribute('disabled', 'disabled')
        if (this.hasSetListNameWarningTarget) {
          this.setListNameWarningTarget.classList.remove('d-none')
        }
        return
      }
      // Hide warning and enable button if name is unique
      if (this.hasSetListNameWarningTarget) {
        this.setListNameWarningTarget.classList.add('d-none')
      }
      // enable the "Add to set list" button
      this.addToSetListButtonTarget.removeAttribute('disabled')
      return
    }

    // Otherwise, disable the "Add to set list" button
    this.addToSetListButtonTarget.setAttribute('disabled', 'disabled')
  }

  /**
   * triggered when the "Clear selection" button is clicked 
   */
  clearRowsChecked() {
    this.rowCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = false
      const wrapper = checkbox.closest('.checkbox-table-wrapper')
      if (wrapper) {
        wrapper.classList.remove('selected')
      }
    })

    this.rowsCheckedValue = []
    this.setListSelectTarget.value = ''
    this.setListNameInputTarget.value = ''

    // Clear localStorage
    localStorage.removeItem('rowsChecked')
    localStorage.removeItem('selectedSetList')
    localStorage.removeItem('newSetListName')

    // clear songs from the form
    const $form = $(this.addToSetListFormTarget)
    $form.find('[name="set_list[songs_ids[]]"]').remove()
  }

  /**
   * triggered when the set list select is changed
   */
  setListSelectChanged() {
    this.updateAddToSetListButtonStatus()
    if (this.saveRowsCheckedStateValue) {
      localStorage.setItem('selectedSetList', this.setListSelectTarget.value)
      // If a set list is selected, remove any saved name input
      if (this.setListSelectTarget.value) {
        localStorage.removeItem('newSetListName')
        // Also clear the input value
        if (this.hasSetListNameInputTarget) {
          this.setListNameInputTarget.value = ''
        }
      }
    }
  }

  /**
   * triggered when the set list name input is changed
   */
  setListNameInputChanged() {
    this.updateAddToSetListButtonStatus()
    if (this.saveRowsCheckedStateValue) {
      if (this.setListNameExists(this.setListNameInputTarget.value)) {
        // Clear storage if name already exists
        localStorage.removeItem('newSetListName')
      } else {
        // Save only if name is unique
        localStorage.setItem('newSetListName', this.setListNameInputTarget.value)
      }
    }
  }

  /**
   * triggered when the "Add songs to set list" button is clicked
   */
  submitAddSongsToSetListForm() {
    const $form = $(this.addToSetListFormTarget)
    
    $form.find('[name="set_list[songs_ids[]]"]').remove()
    this.rowsCheckedValue.forEach(songObj => {
      $form.append(`<input type="hidden" name="set_list[songs_ids][]" value="${songObj.id}">`)
    })

    // Clear localStorage
    localStorage.removeItem('rowsChecked')
    localStorage.removeItem('selectedSetList')
    localStorage.removeItem('newSetListName')
    
    $form.trigger('submit.rails')
  }


  /**
   * Check if the set list name already exists
   */
  setListNameExists(inputValue) {
    return this.setListsNamesValue.some((name) =>
      name.toLowerCase() === inputValue.toLowerCase()
    )
  }

}