$(document).ready(function () {

  let confirmIntructions = document.getElementById('confirm-video-instructions')
  if (confirmIntructions) {
    confirmIntructions.addEventListener('click', function(){
      if (confirmIntructions.checked) {
        document.getElementById('direct_upload_fields').classList.remove('form-block-disabled')
        confirmIntructions.closest(".card").classList.add('bg-success-pale','border-success')
      } else {
        document.getElementById('direct_upload_fields').classList.add('form-block-disabled')
        confirmIntructions.closest(".card").classList.remove('bg-success-pale','border-success')
      }
    })
    
  }

  let progressBar = document.querySelector('.progress-bar');

  const input = document.querySelector('[data-id=direct_upload_input]')
  if (!input) { return }
  
  input.addEventListener('change', (event) => {
    document.getElementById('upload_video_audition_btn').classList.remove('disabled')
    const maxSize = 419430400; // Set maximum file size to 400 MB
    Array.from(input.files).forEach(function (file) {
      const form = document.querySelector('form');
      const fileInput = form.querySelector('input[type="file"]');
      fileInput.setCustomValidity('');
      if (file.size > maxSize) {
        fileInput.setCustomValidity('File size is too large. Maximum size is 400 MB.');
        form.reportValidity();
      }
    })
  })


  let replaceLink = document.getElementById('replace-direct_upload_video')
  if (replaceLink) {
    replaceLink.addEventListener('click', function(){
      document.getElementById('direct_upload_fields').classList.remove('d-none')
    })
    
  }

  addEventListener("direct-upload:start", event => {
    if (!progressBar) { return }
    
    let submitButton = document.querySelector('input[type="submit"]');
    submitButton.classList.add('d-none');
    document.querySelector('.progress').classList.remove('d-none')
  })

  addEventListener("direct-upload:progress", event => {
    if (!progressBar) { return }

    const { progress } = event.detail
    progressBar.style.width = `${progress}%`;
    progressBar.setAttribute('aria-valuenow', progress);
    progressBar.innerHTML = `${progress.toFixed(2)}%`;

  })
})
