import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'artistInput',
    'titleInput',
    'songsApproved',
    'songsPending',
    'songsRejected',
    'songStatusMessage',
    'submitButton'
  ]

  static values = {
    songsApproved: Array,
    songsPending: Array,
    songsRejected: Array
  }

  connect() {
    const autocompleteArtist = document.getElementById('autocomplete-artist')
    const autocompleteSong = document.getElementById('autocomplete-song')

    if (autocompleteArtist) {
      autocompleteArtist.addEventListener('autocomplete.change', this.autocompleteArtistChange.bind(this))
      autocompleteArtist.addEventListener('load', this.autocompleteSongLoad.bind(this))
    }
    
    if (autocompleteSong) {
      autocompleteSong.addEventListener('load', this.autocompleteSongLoad.bind(this))
    }

    if (this.hasTitleInputTarget) {
      this.titleInputTarget.addEventListener('focus', this.onTitleInputFocus.bind(this))
      this.titleInputTarget.addEventListener('blur', this.onTitleInputBlur.bind(this))
    }
  }

  /**
   * Triggered when the artist input value changes by using the autocomplete
   */
  autocompleteArtistChange(event) {
    this.updateAutocompleteSongUrl(event.detail.value)
    this.updateSongStatusMessage()
  }

  /**
   * Triggered when the artist input value changes by typing
   */
  artistInputChange(event) {
    this.updateAutocompleteSongUrl(event.target.value)
    this.updateSongStatusMessage()
  }

  /**
   * Triggered when the song title input on focus
   */
  onTitleInputFocus() {
    this.songStatusMessageTarget.classList.add('d-none')
  }

  /**
   * Triggered when the song title input not on focus
   */
  onTitleInputBlur() {
    this.updateSongStatusMessage()
  }

  /**
   * Triggered when the song title input value changes by typing
   */
  titleInputChange() {
    this.updateSongStatusMessage()
  }

  /**
   * Triggered when the song autocomplete controller loads the results
   */
  autocompleteSongLoad() {
    this.songsApprovedValue = this.extractSongsFromTargets(this.songsApprovedTargets)
    this.songsPendingValue = this.extractSongsFromTargets(this.songsPendingTargets)
    this.songsRejectedValue = this.extractSongsFromTargets(this.songsRejectedTargets)
  }

  /**
   * Update the URL of the autocomplete song controller based on the artist value
   */
  updateAutocompleteSongUrl(value) {
    const autocompleteController = this.application.getControllerForElementAndIdentifier(document.getElementById('autocomplete-song'), 'autocomplete')
    autocompleteController.urlValue = `/program_team/songs/autocomplete_songs?artist=${value}`
  }/**
   * Update the song status message based on the song input value.
   * If the song exists, is pending or rejected, show the message.
   * Otherwise, hide it.
   */


  updateSongStatusMessage() {
    const artist = this.artistInputTarget.value.toLowerCase()
    const title = this.titleInputTarget.value.toLowerCase()
    const inputSong = { artist, title }

    let statusHTML = ''
    let song = null

    // check if input song exists in any state-list
    if (this.findSong(this.songsApprovedValue, inputSong)) {
      song = this.findSong(this.songsApprovedValue, inputSong)
      statusHTML = this.getApprovedStatusHTML(song)
    } else if (this.findSong(this.songsPendingValue, inputSong)) {
      song = this.findSong(this.songsPendingValue, inputSong)
      statusHTML = this.getPendingStatusHTML(song)
    } else if (this.findSong(this.songsRejectedValue, inputSong)) {
      song = this.findSong(this.songsRejectedValue, inputSong)
      statusHTML = this.getRejectedStatusHTML(song)
    }

    this.songStatusMessageTarget.innerHTML = statusHTML

    if (statusHTML) {
      if(this.titleInputTarget.matches(':focus')){
        this.songStatusMessageTarget.classList.add('d-none')
      } else{
        this.songStatusMessageTarget.classList.remove('d-none')
      }
      this.submitButtonTarget.disabled = true
    } else {
      this.songStatusMessageTarget.classList.add('d-none')
      this.submitButtonTarget.disabled = false
    }
  }

  getApprovedStatusHTML(song) {
    return `
      <div class="card-footer d-flex align-items-center justify-content-between p-2 bg-success-pale">
        <p class="text-small"><span class="text-success font-weight-bold">Approved</span> to use on MOC Programs.</p>
        <a href="/program_team/songs/${song.id}" target="_blank" class="btn btn-dark btn-sm flex-shrink-0" title="view song">View Song</a>
      </div>
    `
  }

  getRejectedStatusHTML(song) {
    return `
      <div class="card-footer d-flex align-items-center justify-content-between p-2 bg-danger-light">
        <p class="text-small"><span class="text-danger font-weight-bold">Not Suitable</span> for MOC Programs.</p>
        <a href="/program_team/songs/${song.id}" target="_blank" class="btn btn-dark btn-sm flex-shrink-0" title="view song">View Song</a>
      </div>
    `
  }

  getPendingStatusHTML(song) {
    return `
      <div class="card-footer d-flex align-items-center justify-content-between p-2 bg-light">
        <p class="text-small"><span class="text-warning font-weight-bold">Pending</span>. MOC review.</p>
        <a href="/program_team/songs/${song.id}" target="_blank" class="btn btn-dark btn-sm flex-shrink-0" title="view song">View Song</a>
      </div>
    `
  }
  
  /**
   * Return the song if it exists in the songs array, undefined otherwise
   */
  findSong(songsArray, song) { // skipcq: JS-0105
    return songsArray.find((s) => s.artist === song.artist && s.title === song.title)
  }

  /**
   * Extract the songs from the targets, ensuring there are no duplicates
   */
  extractSongsFromTargets(targets) { // skipcq: JS-0105
    const songs = []

    targets.forEach(target => {
      const artist = target.querySelector('[data-song-artist]').textContent.toLowerCase()

      const titleElement = target.querySelector('[data-song-title]')
      const title = titleElement.textContent.toLowerCase()
      const id = titleElement.getAttribute('data-song-id')

      if (!songs.some((s) => s.id === id)) {
        songs.push({ id, artist, title })
      }
    })

    return songs
  }
}
