import ClipboardJS from "clipboard";

$(document).ready(function () {
  $(".clear-filters-link").on("click", function (e) {
    e.preventDefault();
    $(".hospital-filter").each(function () {
      {
        $(this).val($(this).data("default"));
        $(this).trigger("change");
      }
    });
  });

  // bind volunteer-badge-tooltips to Manage Volunteer modal container when modal is open
  $('#viewHospitalVolunteersModal').on('shown.bs.modal', function() {
    $('[data-toggle="tooltip"]').tooltip({
      container: $(this)
    });

    if ( $( "[data-clipboard-modal]" ).length ) {
      let clipboardModal;
      clipboardModal = new ClipboardJS( "[data-clipboard-modal]",
        {container: document.querySelectorAll("[data-clipboard-container]")[0] })
    }
  });

  $('[data-manageVolunteersLink]').on("click", function (e) {
    $("#viewVolunteersFiltersClearAll")[0].click()
  })

  $("#hospital_same_onboarding_requirements_as_guide").on("click", function (e) {
    $('#musicians_requirements_div').toggle()
  })

  $("#submitHospital").on("click", function (e) {
    e.preventDefault();

    let params = $("#formHospital").serializeArray();

    $.ajax({
      dataType: "json",
      method: "POST",
      url: "/program_team/hospitals",
      data: params,
      success: function (data) {
        window.location.href = "/program_team/hospitals";
      },
      error: function (data) {
        console.log(data);
        let response = JSON.parse(data.responseText);
        let items = "";

        $.each(response.errors, function (index, error) {
          items += `<li>${error}</li>`;
        });

        $(".errors").html(`
          <h4>${response.errors.length} errors prohibited this hospital from being saved:</h4>
          <ul>${items}</ul>
        `);

        $("#newHospital").animate({ scrollTop: 0 }, "slow");
      },
    });
  });
  
  // Delete hospital confirmation modal content change, in the future
  // there are many confirmation modals with similar structure, in the future we should make more unified system
  $('#deleteHospitalModal').on('show.bs.modal', function (event) {
    var name = $(event.relatedTarget).data('hospital-name')
    var path = $(event.relatedTarget).data('hospital-path') 
    var modal = $(this)
    modal.find("[data-modal-title]").text('Delete ' + name)
    modal.find("[data-modal-path]").attr("href", path);
  });
});
