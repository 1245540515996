import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["countryDropdown", "zipField", "countryDropdownContainer"];

  connect() {
    this.toggleZipField();
  }

  toggleZipField() {
    const isUnitedStates = this.countryDropdownTarget.value === "US";
    this.zipFieldTarget.style.display = isUnitedStates ? "block" : "none";
    
    if (isUnitedStates) {
      this.countryDropdownContainerTarget.classList.add("col-md-8");
      this.countryDropdownContainerTarget.classList.remove("col-12");
    } else {
      this.countryDropdownContainerTarget.classList.remove("col-md-8");
      this.countryDropdownContainerTarget.classList.add("col-12");
    }
  }
}
