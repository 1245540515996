$( function() {

  $('[data-toggle-field]').on('change', function (e) {
    let $field = $(this);
    let toggleField = $field.data().toggleField
    let toggleAction = $field.data().toggleAction

    let isTrueRadio = $(this).val()
    let $subtextNode = $("#" + toggleField + "");

    if ($field.is(':radio')) {
      if ($subtextNode.length == 0) {
        return;
      }

      if (typeof toggleAction !== "undefined") { // For cases when we have radio button value is not true/false toggleCoundition should be present
        if (toggleAction === 'show') {
          return $subtextNode.slideDown()
        } else {
          return $subtextNode.slideUp()
        }
      } else {
        if (isTrueRadio === 'true') { // For cases when true radio button toggles field
          return $subtextNode.slideDown()
        } else {
          let $secondaryDiv= $("#" + toggleField);
          if ($secondaryDiv) {
            $secondaryInput = $secondaryDiv.find('input')
            if ($secondaryInput) { $secondaryInput.val(''); }
          }
          return $subtextNode.slideUp()
        }
      }
    } else if ($field.is(':checkbox')) { // For cases when checked checkbox toggles field
      let isChecked = e.target.checked;

      if ($subtextNode.length == 0) {
        return;
      }

      let $volunteerHasBeenReferred = $("#volunteer_application_has_been_referred");
      let isReferredToggle = toggleField === 'were-referred';
      const selectedOptions = $('input[name="volunteer_application[how_did_hear_about_moc][]"]:checked').map(function() {
        return $(this).val();
      }).get();

      let anyReferreallOptionsSelected = selectedOptions.some(function(option) {
        return ['Referral', 'Other'].includes(option);
      });


      if (isChecked) {
        $volunteerHasBeenReferred.val(isReferredToggle ? true : $volunteerHasBeenReferred.val());
        return $subtextNode.slideDown();
      } else {
        if (anyReferreallOptionsSelected) { return; }
        
        $volunteerHasBeenReferred.val(isReferredToggle ? false : $volunteerHasBeenReferred.val());
        return $subtextNode.slideUp();
      }

    } else if ($field.is('select')) { // For cases when select option toggles field
      toggleAction = $field.find('option:selected').data('toggle-action');
      if (toggleAction === 'show') {
        return $subtextNode.slideDown()
      } else {
        return $subtextNode.slideUp()
      }
    }
  } );
} );
