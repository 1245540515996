$(document).ready(function () {
  $('.rejectVA').on('click', function (e) {

    let id = $(this).data('id');

    $.ajax({
      dataType: "json",
      method: 'GET',
      url: "/program_team/volunteer_applications/" + id,
      success: function (data) {
        $('#rejectVAPath').attr('href', data.reject_path);
      },
      error: function (data) {
        console.log(data);
      }
    });
  });

  $('.approveVA').on('click', function (e) {
    let id = $(this).data('id');
    $.ajax({
      dataType: "json",
      method: 'GET',
      url: "/program_team/volunteer_applications/" + id,
      success: function (data) {
        $('#approveVAPath').attr('href', data.approve_path);
      },
      error: function (data) {
        console.log(data);
      }
    });
  });

  $('.clear-filters-link').on('click', function (e) {
    e.preventDefault();
    $('.volunteer-application-filter').each(function () {
      {
        $(this).val($(this).data('default'));
        $(this).trigger('change');
      }
    });
  });

  let stickyElement = $("[data-element-sticky]"),
    stickyClass = "sticky-pin",
    stickySibling = $("[data-element-sticky-sibling]"),
    stickyWrapperElement = $("[data-element-sticky-wrapper]"),
    stickyPos = stickyElement.length && stickyElement.offset().top-80; //Distance from the top of the window.

  // Sticker function:
  function sticker() {
    let winTop = $(this).scrollTop();
    stickyElement.removeAttr('style');
    stickyElement.css({'width': stickyWrapperElement.width(), 'left': stickyElement.offset().left});

    //Check element position:
    if (winTop >= stickyPos) {
      stickyElement.addClass(stickyClass);
      stickySibling.addClass("pt-5");
    }
    if (winTop < stickyPos) {
      stickyElement.removeClass(stickyClass);
      stickySibling.removeClass("pt-5");
    }
  }

  function initSticker(){
    if($(window).width() > 768){
      sticker(); //Run.
      // Function trigger:
      $(window).on('scroll', function(){
        sticker();
      });
    }
  }

  if(stickyElement.length && stickyWrapperElement.length){
    initSticker(); //init Sticker.
    // Function trigger:
    $(window).resize(function(){
      initSticker();
    });
  }

  // sticky bottom element - not doable in stimulus
  let stickyBottomElement = $("[data-sticky-bottom]");
  function stickBottomCheckOffset() {
    var winScroll = document.documentElement.scrollTop;
    var height = document.documentElement.scrollHeight - document.documentElement.clientHeight - $('#footer').height();
    var scrolled = (winScroll / height) * 100;
    if (stickyBottomElement.offset().top + stickyBottomElement.height() >= $('#footer').offset().top - 44) {
      stickyBottomElement.removeClass("fixed-bottom");
      document.getElementById("stepScrollProgress").style.width = "100%";
    };
    if($(document).scrollTop() + window.innerHeight < $('#footer').offset().top ) {
      stickyBottomElement.addClass("fixed-bottom")
      document.getElementById("stepScrollProgress").style.width = scrolled + "%";
    }; 
  }


  if(stickyBottomElement.length){
    stickBottomCheckOffset();
    $(window).on('scroll', function(){
      stickBottomCheckOffset();
    });
  }

});
