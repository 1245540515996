import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.checkForSuccessMessage();
  }

  checkForSuccessMessage() {
    const urlParams = new URLSearchParams(window.location.search);
    const showSuccess = urlParams.get('showSuccess');
    if (showSuccess === 'true') {
      window.notifier.success('Application successfully updated');
      urlParams.delete('showSuccess');
      window.history.replaceState(null, null, "?" + urlParams.toString());
    }
  }
}
