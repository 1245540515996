import ClipboardJS from "clipboard";

$(document).ready(function () {

  if ( $( "[data-clipboard-main]" ).length ) {
    let clipboard;
    clipboard = new ClipboardJS( "[data-clipboard-main]")
     clipboard.on( "success", function(e) {
      clipboard_feedback( e );
    });
  }

  if ( $( "[data-clipboard-modal]" ).length ) {
    let clipboardModal;
    clipboardModal = new ClipboardJS( "[data-clipboard-modal]",
      {container: document.querySelectorAll("[data-clipboard-container]")[0] })

    clipboardModal.on( "success", function(e) {
      clipboard_feedback( e );
    });
  }

  function clipboard_feedback ( event ) {
    $( event.trigger ).after( "<span class=\"clipboard__feedback\" data-clipboard-feedback>Copied</span>" );
    $( "[data-clipboard-feedback]" ).fadeOut( 800, function() {
      $( this ).remove();
    } );
    event.clearSelection();
  }
  
});
