import AWN from "awesome-notifications"


let globalOptions = {
  position: "top-right",
  labels: {
    success: ""
  }
}

window.notifier = new AWN(globalOptions);
