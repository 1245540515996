import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["smsButton", "emailButton", "countdown", "countdownDiv", "verifyForm", "chooseVerification", "hereText"]
  static values = { countdownActive: Boolean }

  connect() {
    this.countdownActiveValue = false;
    this.toggleVisibility(this.hereTextTarget, true);
    this.hereTextTarget.addEventListener('click', () => {
      if (!this.countdownActiveValue) {
        this.toggleVisibility(this.chooseVerificationTarget, true);
        this.toggleVisibility(this.verifyFormTarget, false);
      }
    });
  }

  blockButtons() {
    this.clearFormAndErrors();
    this.countdownActiveValue = true;
    this.toggleVisibility(this.countdownTarget, true);
    this.toggleVisibility(this.verifyFormTarget, true);
    this.toggleVisibility(this.hereTextTarget, false);
    this.toggleVisibility(this.chooseVerificationTarget, false);
    let countdownTime = 30;

    const intervalId = setInterval(() => {
      countdownTime -= 1;
      this.countdownTarget.textContent = `You can resend it in ${countdownTime} seconds.`;
      if (countdownTime <= 0) {
        clearInterval(intervalId);
        this.toggleVisibility(this.countdownTarget, false);
        this.countdownActiveValue = false;
        this.toggleVisibility(this.hereTextTarget, true);
      }
    }, 1000);
  }

  clearFormAndErrors() {
    const verificationCodeInput = this.verifyFormTarget.querySelector('input[name="verification_code"]');
    if (verificationCodeInput) { verificationCodeInput.value = ''; }

    // Clear any error messages
    const errorDiv = this.verifyFormTarget.querySelector('#error_div');
    if (errorDiv) { errorDiv.textContent = ''; }
  }

  toggleVisibility(element, show) {
    element.classList.toggle('d-none', !show);
    element.classList.toggle('d-block', show);
  }
}
