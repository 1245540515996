import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  submit(event) {
    event.preventDefault()
    const form = event.target.closest("form")
    const url = new URL(form.action)
    const formData = new FormData(form)

    for (let [key, value] of formData.entries()) {
      if (value) {
        url.searchParams.set(key, value)
      }
    }

    // Remove the page parameter
    url.searchParams.delete("page")

    window.location.href = url.toString()
  }
}
