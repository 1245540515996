import 'jquery-input-mask-phone-number';
import 'inputmask';

$(document).ready(function () {
  $('.phone-number-mask').usPhoneFormat({
    format: '(xxx) xxx-xxxx',
  });

  let percentageMask = new Inputmask({
    radixPoint: ".",
    alias: 'percentage',
    allowMinus: false,
    digits: 1,
    max: 100,
    showMaskOnHover: false
  });

  let zipMask = new Inputmask({
    regex: "[0-9]{5}"
  });

  percentageMask.mask($('[data-percent-number-mask]'));
  zipMask.mask($('[data-zip-mask]'));
});
