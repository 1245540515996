$(document).ready(function () {
  
  function hideConfirmationOnShiftsVolunteersModal() {
    document.getElementById('confirmationShiftModal').classList.add('d-none')
    document.getElementById('preSubmitVolunteerModalShift').classList.remove('d-none')
    document.querySelector('[data-shift-volunteer-list]').classList.remove('d-none')
  }

  function updateNumberOfVolunteersOnConfirmationMessage() {
    let volunteersCount = $(".assign-volunteer-checkbox:checkbox:checked").length
    document.getElementById('volunteersCountconfirmationShiftModal').innerHTML = volunteersCount
    

    if (volunteersCount == 0) {
      document.getElementById('preSubmitVolunteerModalShift').disabled = true
    }
    else {
      document.getElementById('preSubmitVolunteerModalShift').disabled = false
    }

    document.querySelector('[data-shift-volunteer-confirmation-list]').innerHTML = '<p class="mt-2 mb-0"><b>Volunteer list:</b></p>'
    document.querySelectorAll('input[class~="assign-volunteer-checkbox"]:checked').forEach(function (element) {
      document.querySelector('[data-shift-volunteer-confirmation-list]').innerHTML += `
        <p class="mb-0">${element.parentElement.parentElement.querySelector('.title').textContent.trim()}</p>
      `  
    })
    

  }

  function showConfirmationOnShiftsVolunteersModal() {
    document.getElementById('confirmationShiftModal').classList.remove('d-none')
    document.getElementById('preSubmitVolunteerModalShift').classList.add('d-none')
    document.querySelector('[data-shift-volunteer-list]').classList.add('d-none')
    updateNumberOfVolunteersOnConfirmationMessage()
  }

  document.querySelectorAll('[data-target="#addVolunteersToProgram"]').forEach((element) => {
    element.addEventListener('click', hideConfirmationOnShiftsVolunteersModal)
  })
  
  document.getElementById('preSubmitVolunteerModalShift')?.addEventListener('click', showConfirmationOnShiftsVolunteersModal)
  document.getElementById('cancelButtonShiftModal')?.addEventListener('click', hideConfirmationOnShiftsVolunteersModal)

  $( ".volunteer_program_check_box" ).change( function() {
    let volunteerProgramId = $( this ).data( "volunteer-program-id" );
    let selector = `#selected_volunteer_program_id_${volunteerProgramId}`;
    if ( $( this ).is( ":checked" ) ) {
      $( selector ).addClass( "show-label" );
      $( selector ).removeClass( "hide-label" );
    } else {
      $( selector ).removeClass( "show-label" );
      $( selector ).addClass( "hide-label" );
    }
  } );

  $( ".clear-filters-link" ).on( "click", function( e ) {
    e.preventDefault();
    $( ".volunteer-filter" ).each( function() {
      {
        $( this ).val( $( this ).data( "default" ) );
        $( this ).trigger( "change" );
      }
    } );

    $( "[data-default-filter-radio]" ).prop("checked", true);

  } );


  $( "#submitVolunteer" ).on( "click", function( e ) {
    e.preventDefault();

    let params = $( "#formVolunteer" ).serializeArray();

    $.ajax( {
      dataType: "json",
      method: "POST",
      url: "/program_team/volunteers",
      data: params,
      success: function( data ) {
        window.location.href = data.redirect_url;
      },
      error: function( data ) {
        let response = JSON.parse( data.responseText );
        let items = "";

        $.each( response.errors, function( index, error ) {
          items += `<li>${error}</li>`;
        } );

        $( ".errors" ).html( `
          <div class="alert alert-danger">
            <p class="mb-1">${response.errors.length} errors prohibited this volunteer from being saved:</p>
            <ul class="mb-0">${items}</ul>
          </div>
        ` );

        $( "#newVolunteer" ).animate( { scrollTop: 0 }, "slow" );
      }
    } );
  } );


  $( ".removeVolunteerLink" ).on( "click", function( e ) {

    // e.preventDefault();

    let volunteerId = $( this ).data( "volunteer-id" );
      $.ajax( {
        dataType: "json",
        method: "GET",
        url: "/program_team/volunteers/" + volunteerId,
        success: function( data ) {
          $( "#removeVolunteerCard" ).empty();
          $( "#removeVolunteerCard" ).html( `
            <div class="volunteers-card-image">
              ${ data.avatar_tag }
            </div>
            <div class="volunteers-card-header">
              ${ data.full_name }
            </div>
  
            <div class="volunteers-card-list">
              <div class="volunteers-card-list__item">
                <strong>Location:</strong>
                ${ data.city }
              </div>
  
              <div class="volunteers-card-list__item">
                <strong>Type:</strong>
                ${ data.volunteer_type_pretty }
              </div>
  
              <div class="volunteers-card-list__item">
                <strong>Time Zone:</strong>
                ${ data.time_zone }
              </div>
            </div>
          ` );

          $( "#removeVolunteerPath" ).attr( "href", data.deactivate_path );
        },
        error: function( data ) {
          console.log( data );
        }
      } );
  } );


  $( "#add-volunteer-filter-apply" ).on( "click", function( e ) {
    e.preventDefault();

    let type = $( "#type-select-modal" ).val();
    let branch = $( "#branch-select-modal" ).val();
    let timezone = $( "#timezone-select-modal" ).val();
    let shiftId = $("#manage-volunteers").data("shiftId");
    let status = $('#status-select-modal').val();
    let skipDeclinedVolunteers = $( ".invite-volunteers-button" ).data( "skip-declined-volunteers" );

    $.ajax( {
      dataType: "json",
      method: "GET",
      url: "/program_team/volunteers",
      data: {
        volunteer_type_id: type,
        branch_id: branch,
        time_zone: timezone,
        shift_id: shiftId,
        skip_declined_volunteers: skipDeclinedVolunteers,
        only_available: true,
        status: status
      },
      success: function( data ) {
        $( ".add-volunteers" ).empty();
        $( ".add-volunteers" ).data( "volunteer-type", type );

        $.each( data.volunteers, function( i, volunteer ) {
          $( ".add-volunteers" ).append( `
            <div class="col-12 col-md-6 col-xl-4">
              <div class="checkbox-field shift-item-field">
                <label class="checkbox">
                  <input type="checkbox" class="volunteer-checkbox assign-volunteer-checkbox" name="volunteer-name-${ volunteer.id }" id="volunteer-name-${ volunteer.id }" data-volunteer-id="${ volunteer.id }">
                  <span class="checkmark"></span>
                </label>
                <label class="checkbox-label" for="volunteer-name-${ volunteer.id }">
                  <span class="checkbox-label__image">
                    ${ volunteer.avatar_tag }
                  </span>
                  <span class="checkbox-label-inner">
                    <span class="checkbox-label-inner__item title">
                      <strong>
                        ${ volunteer.full_name }
                      </strong>
                    </span>
                    <span class="checkbox-label-inner__item"><strong>Type:</strong> ${ volunteer.volunteer_type_pretty }</span>
                    <span class="checkbox-label-inner__item"><strong>Branch:</strong> ${ volunteer.branch  }</span>
                    <span class="checkbox-label-inner__item"><strong>Last Shift:</strong> ${ volunteer.last_shift  }</span>
                    <span class="checkbox-label-inner__item"><strong>Next Shift:</strong> ${ volunteer.next_shift  }</span>
                  </span>
  
                </label>
              </div>
            </div>
          ` );
        });
        document.querySelectorAll('.assign-volunteer-checkbox').forEach((element) => {
          element.addEventListener('change', updateNumberOfVolunteersOnConfirmationMessage)
        })
        updateNumberOfVolunteersOnConfirmationMessage()
        hideConfirmationOnShiftsVolunteersModal()
      },
      error: function( data ) {
        console.log( data );
      }
    } );
  } );

  let programId;

  $( ".add-volunteer-button" ).on( "click", function() {
    programId = $( this ).data( "program-id" );
  } );

  $( "#addVolunteerSubmit" ).on( "click", function( e ) {
    e.preventDefault();
    let volunteerIds = [];

    $( ".volunteer-checkbox:checkbox:checked" ).each( function() {
      volunteerIds.push( $( this ).data( "volunteer-id" ) );
    } );

    $.ajax( {
      dataType: "json",
      method: "PUT",
      url: `/program_team/programs/${programId}/add_volunteers`,
      data: { volunteer_ids: volunteerIds },
      success: function( data ) {
        if ( data.length > 1 ) {
          $( ".add-volunteer-result-text" ).html( `Your volunteers, ${ data.join( ", " ) }, have been successfully added to this program.` );
        } else {
          $( ".add-volunteer-result-text" ).html( `Your volunteer, ${ data }, has been successfully added to this program.` );
        }
      },
      error: function( data ) {
        console.log( data );
      }
    } );
  } );

  let shiftId;

  $( ".assign-volunteer-button" ).on( "click", function() {
    programId = $( this ).data( "program-id" );
    shiftId = $( this ).data( "shift-id" );

    $.ajax( {
      dataType: "json",
      method: "GET",
      url: "/program_team/volunteers",
      data: { program_id: programId },
      success: function( data ) {
        $( "#assignVolunteersList" ).empty();
        $( "#assignVolunteerSubmit" ).attr( "data-shift-id", shiftId );
        $( "#assignVolunteerWithoutEmailSubmit" ).attr( "data-shift-id", shiftId );
        $( "#assignVolunteerSubmit" ).data( "shift-id", shiftId );
        $( "#assignVolunteerWithoutEmailSubmit" ).data( "shift-id", shiftId );
        $.each( data.volunteers, function( i, volunteer ) {
          $( "#assignVolunteersList" ).append( `
            <div class="col-12 col-md-6 col-xl-4">
              <div class="checkbox-field shift-item-field">
                <label class="checkbox">
                  <input type="checkbox" class="assign-volunteer-checkbox" name="volunteer-name-${ volunteer.id }" id="volunteer-name-${ volunteer.id }" data-volunteer-id="${ volunteer.id }">
                  <span class="checkmark"></span>
                </label>
                <label class="checkbox-label" for="volunteer-name-${ volunteer.id }">
                  <span class="checkbox-label__image">
                    ${ volunteer.avatar_tag }
                  </span>
                  <span class="checkbox-label-inner">
                    <span class="checkbox-label-inner__item title">
                      <strong>
                        ${ volunteer.full_name }
                      </strong>
                    </span>
                    <span class="checkbox-label-inner__item"><strong>Type:</strong> ${ volunteer.volunteer_type_pretty }</span>
                    <span class="checkbox-label-inner__item"><strong>Branch:</strong> ${ volunteer.branch  }</span>
                    <span class="checkbox-label-inner__item"><strong>Time Zone:</strong> ${ volunteer.local_timezone_abbr }</span>
                  </span>
  
                </label>
              </div>
            </div>
          ` );
        } );
      },
      error: function( data ) {
        console.log( data );
      }
    } );
  });

  $( "#assignVolunteerSubmit, #assignVolunteerWithoutEmailSubmit" ).on( "click", function( e ) {
    e.preventDefault();
    let volunteerIds = [];
    let shiftId = $(this).data("shift-id");
    let sendEmail = $("#assignVolunteerSubmit").data("action") === 'invite' ? true : null;
    let volunteerTypeId = $( ".add-volunteers" ).data( "volunteer-type" );

    $( ".assign-volunteer-checkbox:checkbox:checked" ).each( function() {
      volunteerIds.push( $( this ).data( "volunteer-id" ) );
    } );

    $('.user-profile-info').addClass('blur');
    $('.loading').removeClass('d-none');
    $('.user-profile-info button').prop("disabled", true);
    $('.user-profile-info input').prop("disabled", true);

    $.ajax( {
      dataType: "json",
      method: "POST",
      url: "/program_team/invite_volunteers",
      data: {
        volunteer_ids: volunteerIds,
        shift_id: shiftId,
        send_email: sendEmail,
        invited_as_type_id: volunteerTypeId
      },
      success: function( data ) {
        let guideContainerId = "#shift-" + shiftId + "-guides";
        let musicianContainerId = "#shift-" + shiftId + "-musicians";

        $( guideContainerId ).html( "" );
        $( musicianContainerId ).html( "" );

        $.each( data.guides, function( i, volunteer ) {
          let cardId = "shift-" + shiftId + "-volunteer-" + volunteer.id;

          $( guideContainerId ).append( `
            <div class="row volunteer-card mb-3" id="${cardId}">
                <div class="col-12"><hr class="mt-0"></div>
                <div class="col-2">
                  <div class="volunteers-cards-image">
                    ${ volunteer.avatar_tag.includes('volunteers-cards-image') ? '' : volunteer.avatar_tag }
                  </div>
                </div>
                <div class="col-6 my-auto pl-0">
                    <strong>
                    ${ volunteer.full_name }
                    </strong>
                    <br />
                    <small><em>${volunteer.confirmed ? "" : "Invited"}</em></small>
                </div>
                <div class="col-4 my-auto text-right">
                  <a href="#" class="btn btn-sm btn-outline-danger removeVolunteerFromShift" data-shift-id="${ shiftId }" data-volunteer-id="${ volunteer.id }">
                    <svg class="icon flex-shrink-0 mr-1">
                      <use xlink:href="/images/symbol-defs.svg#icon-dismiss"></use>
                    </svg>
                    <span>Remove</span>
                  </a>
                </div>
            </div>
          ` );
        } );

        updateAlternativeHost( data.guides );

        $.each( data.musicians, function( i, volunteer ) {
          let cardId = "shift-" + shiftId + "-volunteer-" + volunteer.id;

          $( musicianContainerId ).append( `
            <div class="row volunteer-card mb-3" id="${cardId}">
                <div class="col-12"><hr class="mt-0"></div>
                <div class="col-2">
                  <div class="volunteers-cards-image">
                    ${ volunteer.avatar_tag.includes('volunteers-cards-image') ? '' : volunteer.avatar_tag }
                  </div>
                </div>
                <div class="col-6 my-auto pl-0">
                    <strong>
                    ${ volunteer.full_name }
                    </strong>
                    <br />
                    <small><em>${volunteer.confirmed ? "" : "Invited"}</em></small>
                </div>
                <div class="col-4 my-auto text-right">
                  <a href="#" class="btn btn-sm btn-outline-danger removeVolunteerFromShift" data-shift-id="${ shiftId }" data-volunteer-id="${ volunteer.id }">
                    <svg class="icon flex-shrink-0 mr-1">
                      <use xlink:href="/images/symbol-defs.svg#icon-dismiss"></use>
                    </svg>
                    <span>Remove</span>
                  </a>
                </div>
            </div>
          ` );
        } );

        $( ".assign-volunteers-text-title" ).html( "You've invited volunteers to this shift" );
        $( ".assign-volunteers-text-desc" ).html( "If you would like to invite another volunteer to this shift, use the \"Invite Volunteers\" link." );
        removeSpinner();
      },
      error: function( data ) {
        let response = JSON.parse( data.responseText );
        $( ".assign-volunteers-text-title" ).html( response.error );
        removeSpinner();
      }
    } );
  } );

  $( "#manage-volunteers" ).on( "click", ".removeVolunteerFromShift", function( e ) {
    e.preventDefault();
    let volunteerId = $( this ).data( "volunteer-id" );
    let shiftId = $( this ).data( "shift-id" );
    let volunteerCardElement = $( "#shift-" + shiftId + "-volunteer-" + volunteerId );

    $.ajax( {
      dataType: "json",
      method: "DELETE",
      url: "/program_team/invite_volunteers/" + volunteerId,
      data: { volunteer_id: volunteerId, shift_id: shiftId },
      success: function( data ) {
        volunteerCardElement.remove();

        if ( $( "#shift-" + shiftId + "-guides" ).children().length == 0 ) {
          $( ".add-volunteers-buttons[data-volunteer-type='guides']" ).removeClass( "d-none" );
        }

        updateAlternativeHost( data.guides );

        if ( $( "#shift-" + shiftId + "-musicians" ).children().length == 0 ) {
          $( ".add-volunteers-buttons[data-volunteer-type='musicians']" ).removeClass( "d-none" );
        }
      },
      error: function( data ) {
        console.log( data );

        let response = JSON.parse( data.responseText );
        $( ".assign-volunteers-text-title" ).html( response.error );
      }
    } );
  } );

  $( document ).on( "change", "input[type=radio][class=shift-radio]", function() {
    $( "#signupConfirmShiftButton" ).prop( "disabled", false );
  } );

  $( "#shift-filter-apply" ).on( "click", function( e ) {
    e.preventDefault();

    var dateRange = $( ".signup-filter#date_range" ).val();
    var programType = $( "#program-type-select" ).val();
    var hospital = $( "#hospital-select-modal" ).val();
    var keyboardAvailable = $( "#keyboard-available" ).is( ":checked" );
    var program = $( "#program-select-modal" ).val();
    var branch = $( "#branch-select-modal" ).val();
    var timeOfDay = $( "#time-select-modal" ).val();
    var volunteerTypeNeededId = $( "#volunteer-needed-select-modal" ).val();

    $.ajax( {
      dataType: "json",
      method: "GET",
      url: "/volunteer/home/show",
      data: {
        date_range: dateRange,
        skip_navigate: true,
        program_type_id: programType,
        hospital_id: hospital,
        keyboard: keyboardAvailable,
        volunteer_type_needed_id: volunteerTypeNeededId,
        program: program,
        branch_id: branch,
        time_of_day: timeOfDay
      },
      success: function( data ) {

        $( "#signupShiftsList" ).empty();
        if ( data.shifts.length == 0 ) {
          $( "#signupShiftsList" ).append( `
            <div class="col-12">
              <p class="text-center py-5">It looks like there are no shifts matching your search.<br>Please update filters and try again.</p>
            </div>
          ` );
        } else {
          $.each( data.shifts, function( i, shift ) {
            $( "#signupShiftsList" ).append( `
              <div class="col-12 col-md-6 col-xl-4">
                <div class="checkbox-field shift-item-field">
                  <label class="checkbox radio">
                    <input type="radio" name="shift-name[]" class="shift-radio" id="shift-name-${ shift.id }" data-shift-id="${ shift.id }" data-program-id="${ shift.slug }">
                    <span class="checkmark"></span>
                  </label>
                  <label class="checkbox-label" for="shift-name-${ shift.id }">
                  <span class="checkbox-label__image">
                    ${ shift.thumbnail_tag }
                  </span>
                    <span class="checkbox-label-inner">
                    <span class="checkbox-label__item title"><strong>${ shift.title }</strong></span>
                    <span class="checkbox-label__item">${ shift.full_time } ${ shift.local_timezone_abbr }</span>
                    <span class="checkbox-label__item ${ shift.in_person ? "" : "d-none" }">${ shift.day_time }</span>
                    <span class="checkbox-label__item ${ shift.in_person ? "" : "d-none" }"><b>Hospital: </b>${ shift.hospitals }</span>
                    <span class="checkbox-label__item ${ shift.in_person ? "" : "d-none" }"><b>Address: </b>${ shift.address }</span>
                    <span class="checkbox-label__item"><b>Demographics: </b>${ shift.program_types }</span>
                  </span>
                  </label>
                </div>
              </div>
            ` );
          } );
        }
      },
      error: function( data ) {
        console.log( data );
      }
    } );
  } );

  $( "[data-program-type-name=\"Musician\"]" ).on( "change", function( e ) {
    if ( $( "[data-program-type-name=\"Musician\"]" ).is( ":checked" ) ) {
      $( ".musician-only-fields" ).removeClass( "d-none" );
    } else {
      hideMusiciansFields();
    }
  } );

  $( "[data-program-type-name=\"CVT\"]" ).on( "change", function( e ) {
    if ( !$( "[data-program-type-name=\"Guide\"]" ).is( ":checked" ) ) {
      $( "[data-program-type-name=\"CVT\"]" ).prop( "checked", false );
    }
  });

  $( "[data-program-type-name=\"Guide\"]" ).on( "change", function( e ) {
    if ( !$( "[data-program-type-name=\"Guide\"]" ).is( ":checked" ) ) {
      $( "[data-program-type-name=\"CVT\"]" ).prop( "checked", false );
      $( ".js-guide-only-fields" ).addClass( "d-none" );
    }else {
      $( ".js-guide-only-fields" ).removeClass( "d-none" );
    }
  });

  function updateAlternativeHost( guides ) {
    let host = null;

    if (guides.length > 0) {
      let emails = guides.map(volunteer => {
        if (volunteer.confirmed)
          return volunteer.zoom_email;
      })
      host = emails.filter((e) => e).join(",");
    }

    $( "[data-field-name=\"alternative_host\"]" ).val( host );
  }

  function removeSpinner() {
    $( ".user-profile-info" ).removeClass( "blur" );
    $( ".loading" ).addClass( "d-none" );
    $( ".user-profile-info button" ).prop( "disabled", false );
    $( ".user-profile-info input" ).prop( "disabled", false );
  }

  $( "[data-sort-arrows]" ).on( "click", function( e ) {
    let sortOrder = $(this).data('sort-order')

    $( "#filterForm2" ).append(`<input type="hidden" name="sort_order" value="${sortOrder}" /> `);
    $( "#filterForm2" ).submit();
  });

  function hideMusiciansFields() {
    $( ".musician-only-fields" ).addClass( "d-none" );

    $( ".musician-only-fields" ).find( "input:text, select" ).val( null );
    $( ".musician-only-fields .select2-selection__choice" ).remove();
    $( "[name='volunteer[related_volunteer_ids][]']" ).val( null );
    $( ".musician-only-fields #selected_related_volunteers" ).find( ".main-label" ).each( function() {
      $( "[data-program-type-name=\"Musician\"]" ).addClass( "hide-label" ).removeClass( "show-label" );
    } );
  }
} );
