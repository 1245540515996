import "../../../node_modules/daterangepicker/daterangepicker";
import "./vendor/select2.full.min";

export default function init_select2() {

  if ($('.js-select2').length)
    $('.js-select2').select2({
      escapeMarkup : function(markup) { return markup; }
    });

  if ($('.js-select2--without-search').length)
    $('.js-select2--without-search').select2({
      minimumResultsForSearch: -1,
      escapeMarkup : function(markup) { return markup; }
    });

  if ($('[data-select2-dymanic-options]').length)
    $('[data-select2-dymanic-options]').select2({
      tags: true,
      tokenSeparators: [','],
      escapeMarkup : function(markup) { return markup; }
    });

  if ($('[data-select2-custom-options]').length)
    $('[data-select2-custom-options]').select2({
      tags: true,
      escapeMarkup : function(markup) { return markup; }
    });

  // Please use this select2 initializing class because Select2 & Bootstrap modals have conflicts
  if ($('[data-select2-on-modal]').length)
    $('[data-select2-on-modal]').each(function() {
      let $parentElement = $(this).parent();
      $(this).select2({
       dropdownParent: $parentElement
     });
    })

    if($('[data-select2-dynanic-tags]').length)
      $('[data-select2-dynanic-tags]').select2({
        tags: true,
        tokenSeparators: [','],
        escapeMarkup : function(markup) { return markup; },
        ajax: {
          dataType: 'json',
          delay: 250,
          data: function (params) {
            return {
              search: params.term,
              page: params.page
            };
          },
          processResults: function (data, params) {
            return {
              results: $.map(data, function(element, i) {
                return { id: element.name, text: element.name };
              })
            };
          },
          cache: true
        },
        minimumInputLength: 0,
        escapeMarkup: function (markup) { return markup; },
        allowClear: true,
        placeholder: ''
      });

}

$(document).ready(function(){
  if ($('input[name="date_range"]').length && !$('[data-tracking-sheet-date-range]').length) {

    if ($('#date_range').data('start-date') != undefined && $('#date_range').data('end-date') != undefined) {
      let start_date = new Date($('#date_range').data('start-date'));
      let end_date = new Date($('#date_range').data('end-date'));

      $('input[name="date_range"]').daterangepicker({
        // timePicker: true,
        startDate: start_date,
        endDate: end_date,
        opens: 'center',
        locale: {
          format: 'MM/DD/YYYY'
        }
      });
    }
  }

  if ($('input[data-single-daterange]').length) {
    $('input[data-single-daterange]').daterangepicker({
      singleDatePicker: true,
      autoApply: true,
      opens: 'center',
      "maxDate": new Date(),
      locale: {
        format: 'MM/DD/YYYY'
      },
    });
  }

  if ($('input[data-single-daterange-application]').length) {
    $('input[data-single-daterange-application]').daterangepicker({
      singleDatePicker: true,
      opens: 'center',
      locale: {
        format: 'MM/DD/YYYY'
      },
    });
  }

  if ($('input[data-singlemodal-daterange]').closest('.modal').length) {
    $(document).on("show.bs.modal", ".modal", function (el) {
      $('input[data-singlemodal-daterange]').daterangepicker({
        parentEl: el.target,
        singleDatePicker: true,
        opens: 'center',
        locale: {
          format: 'MM/DD/YYYY'
        }
      });
    });
  }

  if ($('input[data-single-datetimerange]').length) {
    $('input[data-single-datetimerange]').daterangepicker({
      showDropdowns: true,
      singleDatePicker: true,
      timePicker: true,
      timePickerIncrement: 5,
      opens: 'center',
      locale: {
        format: 'MM/DD/YYYY hh:mm A'
      }
    });
  }
  if($('input[data-singlemodal-datetimerange]').closest('.modal').length){
    $( document ).on( "show.bs.modal", ".modal", function(el) {
      $('input[data-singlemodal-datetimerange]').daterangepicker({
        parentEl: el.target,
        showDropdowns: true,
        singleDatePicker: true,
        timePicker: true,
        timePickerIncrement: 5,
        opens: 'center',
        locale: {
          format: 'MM/DD/YYYY hh:mm A'
        }
      });
    } );
  }
});
