import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"]

  initialize() {
    this.formChanged = false;
  }

  connect() {
    this.formTarget.addEventListener('change', (event) => {
      if (event.target.id !== "confirm-video-instructions") {
        this.formChanged = true;
      }
    });

    this.formTarget.addEventListener('submit', () => {
      this.formChanged = false;
    });

    window.addEventListener('beforeunload', (event) => {
      if (this.formChanged) {
        const confirmationMessage = 'It looks like you have been editing something. ' +
                                    'If you leave before saving, your changes will be lost.';
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    });
  }
}
