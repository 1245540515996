$(document).ready(function () {
  function getSelectedShiftIds(){
    let checkboxes = $( ".delete-shifts-check:checked" );
    let ids = [];
    checkboxes.each( function() {
      ids.push( $( this ).data( "id" ) );
    });
    return ids;
  }

  function deleteMultipleShifts(ids) {
    $.ajax( {
      dataType: "json",
      method: "DELETE",
      url: "/program_team/delete_multiple_shifts",
      data: { ids: ids },
      success: function( data ) {
        window.location.reload();
      },
      error: function( data ) {
        console.log( "error", data );
      }
    });
  }

  function changeContactMultipleShifts(ids) {
    $.ajax( {
      dataType: "json",
      method: "POST",
      url: "/program_team/change_contact_multiple_shifts",
      data: { ids: ids, program_manager: $('#program-manager').val() },
      success: function(data) {
        window.location.reload();
      },
      error: function (data) {
        window.notifier.alert('It was not possible to change the shift(s) contact');
      }
    });
  }

  $( "#submitShift" ).on( "click", function( e ) {
    e.preventDefault();

    let params = $( "#formShift" ).serializeArray();
    let url = $( "#formPath" ).val();
    let redirectUrl = $( "#redirectPath" ).val();

    $.ajax( {
      dataType: "json",
      method: "POST",
      url: url,
      data: params,
      success: function( data ) {
        window.location.href = data.redirect_url;
      },
      error: function( data ) {
        let response = JSON.parse( data.responseText );
        let items = "";

        $.each( response.errors, function( index, error ) {
          items += `<li>${error}</li>`;
        } );

        $( ".errors" ).html( `
          <h4>${response.errors.length} errors prohibited this hospital from being saved:</h4>
          <ul>${items}</ul>
        ` );

        $( "#newShift" ).animate( { scrollTop: 0 }, "slow" );
      }
    } );
  } );

  function coveredAsVolunteerType (volunteer_type, i) {
    return `<div class="form-row">
              <div class="col form-group">
                <label class="checkbox radio">
                  <input type="radio" name="covered_as_id" value="${ volunteer_type.id }" class="covered-as-radio" id="covered-as-radio-${ volunteer_type.id }" ${ i === 0 ? 'checked' : '' }>
                  <span class="checkmark"></span>
                </label>
                <label class="checkbox-label" for="covered-as-radio-${ volunteer_type.id }">${ volunteer_type.name }</label>
              </div>
            </div>`;
  }

  function relatedVolunteerCheckBox (volunteer, selected_type) {
    type_id = ''
    volunteer.volunteer_types.find(function(type, index) {
      if(type.name.toLowerCase() == selected_type)
        type_id = type.id
    });
    return `<div class="checkbox-field shift-item-field">
              <label class="checkbox">
                <input type="checkbox" class="related-volunteer-checkbox" id="volunteer-name-${volunteer.id}"  data-volunteer-id="${volunteer.id}" data-volunteer-type-id="${type_id}">
                <span class="checkmark"></span>
              </label>
              <label class="checkbox-label" for="volunteer-name-${volunteer.id}">
                <span class="checkbox-label__image">
                  ${volunteer.avatar_tag}
                </span>
                <span class="checkbox-label-inner">
                  <span class="checkbox-label-inner__item title">
                    <strong>
                      ${volunteer.full_name}
                    </strong>
                  </span>
                </span>
              </label>
            </div>`;
  }

  function relatedVolunteerBlock (volunteers) {
    return `<div class="row">
              <div class="col-12 col-sm-6 col-md-4">
                <div class="row">
                  <div class="col-12">
                    <div class="program-cards__item">
                      ${volunteers}
                    </div>
                  </div>
                </div>
              </div>
            </div>`;
  }

  $(document).on('click', '#signupConfirmShiftButton', function () {
    let shiftId = $('.shift-radio:checked').data('shift-id');
    let programId = $('.shift-radio:checked').data('program-id');
    let volunteerId = $('[data-volunteer-profile-link]').data('volunteerId')
    let musiciansBlock = '';
    let guidesBlock = '';
    let musicianCheckboxes = '';
    let guideCheckboxes = '';

    $.ajax( {
      dataType: "json",
      method: 'GET',
      url: `/volunteer/related_volunteers/`,
      data: {
        volunteer_id: volunteerId,
        shift_id: shiftId
      },
      async: false,
      success: function (data) {
        data.forEach(volunteer => {
          if (volunteer.musician) {
            musicianCheckboxes += relatedVolunteerCheckBox(volunteer, "musician");
          } else if (volunteer.guide) {
            guideCheckboxes += relatedVolunteerCheckBox(volunteer, "guide");
          }
        });
      },
      error: function( data ) {
        console.log( "error", data );
      }
    } );

    if (musicianCheckboxes.length > 0) {
      musiciansBlock = `<div class="program-card-list__item"><strong>Add Musicians:</strong></div>
                        ${relatedVolunteerBlock(musicianCheckboxes)}`
    };

    if (guideCheckboxes.length > 0) {
      guidesBlock = `<div class="program-card-list__item"><strong>Add Guides:</strong></div>
                        ${relatedVolunteerBlock(guideCheckboxes)}`
    };

    $.ajax( {
      dataType: "json",
      method: "GET",
      url: `/volunteer/programs/${programId}/shifts/${shiftId}`,
      async: false,
      success: function (data) {
        let coveredAsBlock = '';

        if (data.can_choose_volunteer_type) {
          let coveredAs = '';
          $.each(data.needed_volunteer_types, function (i, volunteer_type) {
            coveredAs += coveredAsVolunteerType(volunteer_type, i);
          });

          coveredAsBlock = `<div class="program-card-list__item">
                              <strong>Sign up as:</strong>
                              ${coveredAs}
                            </div>`;
        }

        $('#confirmSignUpShiftCard').empty();
        $('#confirmSignUpShiftCard').html(`
            <div class="col-12 col-sm-6 col-md-4 text-right">
              <div class="program-calendar-label">
                <div class="cell-wrap">
                  <div class="cell month">${ data.weekday }</div>
                  <div class="cell date">${ data.month } <span class="num">${ data.day }</span></div>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-6 col-md-4">
              <div class="row">
                <div class="col-12">
                  <div class="program-cards__item">
                    <div class="program-card-image">
                      ${ data.thumbnail_tag }
                    </div>
                    <div class="program-card-list-wrap">
                      <div class="program-card-list">
                        <div class="program-card-list__item"><strong>Title:</strong> <a href="#">${ data.title }</a></div>
                        <div class="program-card-list__item"><strong>Time:</strong> ${ data.full_time } ${ data.local_timezone_abbr }</div>
                        <div class="program-card-list__item"><strong>MOC Program Contact:</strong><br>${ data.program_manager_name }</div>
                        ${coveredAsBlock}
                        
                        ${musiciansBlock}
                        ${guidesBlock}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <a href="#" class="btn btn-dark full-width" id="confirmSignUpShift" data-shift-id="${ data.id }" data-program-id="${ data.slug }" data-confirm="" data-toggle="modal" data-target="#signupResult" data-dismiss="modal">Confirm</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ` );
      },
      error: function( data ) {
        console.log( "error", data );
      }
    } );
  } );

  $(document).on('click', '#confirmSignUpShift', function () {
    let shiftId = $(this).data('shift-id');
    let programId = $(this).data('program-id');
    let volunteers = {};

    $('.related-volunteer-checkbox:checked').each(function () {
      volunteers[$(this).data('volunteer-id')] = $(this).data('volunteer-type-id');
    });

    let covered_as_radio = $('input:checked[name="covered_as_id"]');
    let covered_as_id;
    if (covered_as_radio.length)
      covered_as_id = covered_as_radio.val();

    $.ajax({
      dataType: "json",
      method: "PUT",
      url: `/volunteer/programs/${programId}/shifts/${shiftId}/sign_up`,
      data: {
        related_volunteer_ids: volunteers,
        covered_as_id: covered_as_id
      },
      success: function (data) {
        console.log('success');
        $('#signedShiftLink').prop('href', `/volunteer/programs/${programId}/shifts/${shiftId}`);
      },
      error: function( data ) {
        console.log( "error", data );
      }
    } );
  } );

  // CANCEL SHIFT BY VOLUNTEER

  $( ".cancelShiftLink" ).on( "click", function( e ) {
    let programId = $( this ).data( "program-id" );
    let shiftId = $( this ).data( "shift-id" );

    $.ajax( {
      dataType: "json",
      method: "GET",
      url: `/volunteer/programs/${programId}/shifts/${shiftId}`,
      success: function( data ) {
        $( "#confirmCancelShiftCard" ).empty();
        $( "#confirmCancelShiftCard" ).html( `
          <div class="col-12 col-lg-7">
            <div class="card flex-grow-1">
              <div class="card-header py-2 d-flex align-items-center ${data.in_person ? "bg-primary" : "bg-info-dark"}">
                <div class="d-flex w-100">
                  <svg class="icon icon-size-4 text-contrast-bg mb-0 ml-n1 mr-2 flex-shrink-0">
                    <use xlink:href="/images/symbol-defs.svg#icon-${data.in_person ? "bedside" : "virtual"}"></use>
                  </svg>
                  <p class="w-100 text-white">${ data.title }</p>
                </div>
              </div>
              <div class="row mx-n2 flex-grow-1">
                <div class="col-12 col-sm-2 col-md-3 col-xl-3 px-2">
                  <div class="date-block h-100">
                    <div class="date-block__week bg-secondary">
                      ${ data.weekday }
                    </div>
                    <div class="date-block__date">
                      <p class="d-sm-none">${ data.day } ${ data.month }</p>
                      <p class="d-none d-sm-block">${ data.month }</p>
                      <p class="d-none d-sm-block date-block__date__day">
                        ${ data.day }
                      </p>
                    </div>
                    <div class="date-block__time ${data.in_person ? "bg-moc-pink" : "bg-info-pale"}">
                      ${ data.time } ${ data.local_timezone_abbr }
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-10 col-md-9 col-xl-9 px-sm-2 pb-2">
                  <div>
                    <div class="d-flex align-items-center flex-wrap my-2">
                      <svg class="icon icon-size-4 flex-shrink-0 mr-1">
                        <use xlink:href="/images/symbol-defs.svg#icon-guide"></use>
                      </svg>
                      <span>${data.guide || "TBD"}</span>
                    </div>
                    <div class="d-flex align-items-center flex-wrap mb-2">
                      <svg class="icon icon-size-4 flex-shrink-0 mr-1">
                        <use xlink:href="/images/symbol-defs.svg#icon-musician"></use>
                      </svg>
                      <span>${data.musician || "TBD"}</span>
                    </div>
                  </div>
                  <hr/>
                  <div>
                    <strong>MOC Program Contact</strong>
                    <p>${ data.program_manager_name }</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-7 mt-5">
            <div class="d-flex justify-content-between">
              <a href="#" class="btn btn-danger full-width" id="confirmCancelShift" data-shift-id="${ data.id }" data-confirm="" data-dismiss="modal" data-target="#cancelShiftResult">Yes, Cancel This Shift</a>
              <a href="#" class="btn btn-dark full-width" data-shift-id="${ data.id }" data-confirm="" data-dismiss="modal">No, Don't Cancel</a>
            </div>
          </div>    
        ` );
      },
      error: function( data ) {
        console.log( "error" );
        console.log( data );
      }
    } );
  } );

  $( document ).on( "click", "#confirmCancelShift", function() {
    console.log( "Hello" );
    let shiftId = $( this ).data( "shift-id" );

    $.ajax( {
      dataType: "json",
      method: "POST",
      url: "/volunteer/cancel_shifts",
      data: { shift_id: shiftId },
      success: function( data ) {
        console.log( "success" );

        $( "#cancelShiftResultText" ).empty();
        $( "#cancelShiftResultText" ).html( `
          Your shift at ${ data.title } on ${ data.full_time } has successfully been cancelled
        ` );

        $( "#cancelShiftResult" ).modal().show();
      },
      error: function( data ) {
        console.log( "error" );
        console.log( data );
      }
    } );
  } );

  $( "#cancelShiftResult" ).on( "hidden.bs.modal", function() {
    window.location.href = "/volunteer/cancel_shifts";
  } );


  // DELETE MULTIPLE SHIFTS

  $("#btn-delete-shifts, #btn-change-manager").on("click", function() {
    let ids = getSelectedShiftIds();
    if (ids.length > 0) {
      if (this.id === "btn-delete-shifts") {
        deleteMultipleShifts(ids);
      } else {
        changeContactMultipleShifts(ids);
      }
    }
  });

  $( "#addMusicians, #addGuides" ).on( "click", function() {
    document.querySelector('[data-volunteers-modal-title]').innerHTML = "Add Volunteers";
    $("#volunteersActionConfirmationShiftModal").text("add");
    $( "#preSubmitVolunteerModalShift" ).text( "Add Volunteer" );
    $(".invite-volunteers-button").data("skip-declined-volunteers", false);
    $("#assignVolunteerSubmit").data("action", "add");
  } );

  $("#inviteMusicians, #inviteGuides").on("click", function () {
    document.querySelector('[data-volunteers-modal-title]').innerHTML = "Invite Volunteers";
    $("#volunteersActionConfirmationShiftModal").text("invite");
    $( ".invite-volunteers-button" ).attr( "id", "assignVolunteerSubmit" );
    $( "#preSubmitVolunteerModalShift" ).text( "Invite Volunteer" );
    $(".invite-volunteers-button").data("skip-declined-volunteers", true);
    $("#assignVolunteerSubmit").data("action", "invite");
  } );

  $( "#inviteMusicians, #addMusicians, #inviteGuides, #addGuides" ).on( "click", function( e ) {
    let volunteerType = $( e.target ).data( "volunteer-type" );

    $( "#type-select-modal" ).val( volunteerType ).change();

    $( "#add-volunteer-filter-apply" ).trigger( "click" );
  } );

  $( ".clear-filters-link" ).on( "click", function() {
    $( "#type-select-modal" ).val( "" ).change();
    $( "#branch-select-modal" ).val( "" ).change();
    $( "#timezone-select-modal" ).val( "" ).change();
  } );

  $('#shift_recurring').on('change', function() {
    if ($(this).is(':checked')) {
      $('.recurring-block').removeClass('d-none');;
    } else {
      $('.recurring-block').addClass('d-none');
    }
  });
} );
