$(document).ready(function () {
  document.querySelectorAll('[data-toggle-track]').forEach(function (element) {
    element.addEventListener('change', function () { updateTrackCardsUI(element) });
    updateTrackCardsUI(element) // act on the first load incase the backend is rendering a specific state
  })

  if (document.getElementById('confirm-check-virtual-tracks')) {
    document.getElementById('confirm-check-virtual-tracks').addEventListener('click', function () {
      updateNextButtonVisibilty()
    })
    updateNextButtonVisibilty()
  }
});

function updateTrackCardsUI(element) {
  let $trackCard = $(element).closest('label');
  let isChecked = element.checked;
  let $virtualChecks = $('[data-toggle-track^="virtual"]:checked').length;
  let $bedsideChecks = $('[data-toggle-track^="bedside"]:checked').length;


  if (isChecked) { 
    $trackCard.addClass('active');
    if ($virtualChecks > 0) {
      $('#virtual-track-requirements').slideDown();
      if ($(element).attr("data-toggle-track") == "virtual_musician") {
        unselectStatementsCheckbox()
        $('#virtual-track-requirements-musician').slideDown();
      }
    }
    if ($bedsideChecks > 0) {
      $('#bedside-track-requirements').slideDown();
      
    }
  } else {
    $trackCard.removeClass('active')
    if ($(element).attr("data-toggle-track")=="virtual_musician" ) {
      $('#virtual-track-requirements-musician').slideUp();
    }
    if ($virtualChecks < 1) {
      $('#virtual-track-requirements').slideUp();
      unselectStatementsCheckbox()
    }
    if ($bedsideChecks < 1) {
      $('#bedside-track-requirements').slideUp();
    }
  }
  updateNextButtonVisibilty()
}

function updateNextButtonVisibilty() {
  let $virtualChecks = $('[data-toggle-track^="virtual"]:checked').length;
  let $bedsideChecks = $('[data-toggle-track^="bedside"]:checked').length;
  let totalTracks = $bedsideChecks + $virtualChecks
  let confirmCheckbox = document.getElementById('confirm-check-virtual-tracks')

  if (totalTracks < 1 || (confirmCheckbox && !confirmCheckbox.checked && $virtualChecks > 0) ){
    hideNextButton()
  }
  else {
    showNextButton()
  }
}

function showNextButton() {
  var submitButton = document.querySelector('button[type="submit"]');
  if (submitButton) {
    submitButton.disabled = false;
  }
}

function hideNextButton() {
  var submitButton = document.querySelector('button[type="submit"]');
  if (submitButton) {
    submitButton.disabled = true;
  }
}

function unselectStatementsCheckbox() {
  var confirmCheckElement = document.getElementById('confirm-check-virtual-tracks');
  if (confirmCheckElement) {
    confirmCheckElement.checked = false;
  }
}
