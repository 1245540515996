import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "checkAllCheckbox"]

  connect() {
    this.updateAllCheckAllStates()
  }

  toggleAll(event) {
    const collection = event.currentTarget.dataset.collection
    const isChecked = event.currentTarget.checked
    
    this.checkboxTargets
      .filter(checkbox => checkbox.dataset.collection === collection)
      .forEach(checkbox => {
        checkbox.checked = isChecked
      })
  }

  updateCheckAllState() {
    this.updateAllCheckAllStates()
  }

  updateAllCheckAllStates() {
    const collections = new Set(this.checkboxTargets.map(cb => cb.dataset.collection))
    
    collections.forEach(collection => {
      const checkAllCheckbox = this.checkAllCheckboxTargets.find(cb => cb.dataset.collection === collection)
      const collectionCheckboxes = this.checkboxTargets.filter(cb => cb.dataset.collection === collection)
      
      checkAllCheckbox.checked = collectionCheckboxes.every(cb => cb.checked)
    })
  }
}