$(document).ready(function () {
  if (document.getElementById("search_by_name") && document.getElementById("spinner")) {
    document.getElementById("search_by_name").addEventListener("input", function () {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        let form = this.closest("form");
        document.getElementById("spinner").classList.remove('d-none')
        $.ajax({
          dataType: "script",
          type: form.method,
          url: form.action,
          data: $(form).serialize(),
          success: function(response) {
            document.getElementById("spinner").classList.add('d-none')
          },
          error: function(error) {
            document.getElementById("spinner").classList.add('d-none')
          }
        });
      }, 350);
    });
  }
});
