$( document ).ready( function() {

  if ( $( "[data-input-end-time]" ) && $( "[data-input-end-time]" ).length > 0 ) {
    let endTime = $( "[data-input-end-time]" ).val();
    let countDownEndDate = new Date( endTime );

    let offset = ( new Date() ).getTimezoneOffset();
    countDownEndDate.setHours( countDownEndDate.getHours() + ( -offset ) / 60 );
    countDownEndDate = countDownEndDate.getTime();

    let now = new Date().getTime();
    let distanceEnd = countDownEndDate - now;
    console.log( distanceEnd );

    if ( distanceEnd > 0 ) {
      $( "[data-display-stream-head-finish]" ).hide();
    } else {
      $( "[data-display-stream-head-finish]" ).show();
      $( "[data-display-stream-head-public]" ).hide();
      $( "[data-display-stream-head-waiting]" ).hide();
    }


    let x1 = setInterval( function() {
      now = new Date().getTime();
      distanceEnd = countDownEndDate - now;

      console.log( distanceEnd );

      if ( distanceEnd <= 0 ) {
        clearInterval( x1 );

        $( "[data-display-stream-head-finish]" ).show();
        $( "[data-display-stream-head-public]" ).hide();
        $( "[data-display-stream-head-waiting]" ).hide();
      }
    }, 1000 );
  }

} );
