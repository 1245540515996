$( document ).ready( function() {

  if ( document.querySelector( "[data-display-countdown]" ) !== null ) {
    let startTime = $( "[data-input-start-time]" ).val();
    let countDownDate = new Date( startTime );

    let offset = ( new Date() ).getTimezoneOffset();
    countDownDate.setHours( countDownDate.getHours() + ( -offset ) / 60 );
    countDownDate = countDownDate.getTime();

    let now = new Date().getTime();
    let distance = countDownDate - now;

    if ( distance > 0 ) {
      $( "[data-display-stream-head-waiting]" ).show();
      $( "[data-display-stream-head-public]" ).hide();
    } else {
      $( "[data-display-stream-head-public]" ).show();
      $( "[data-display-stream-head-waiting]" ).hide();
    }

    let x = setInterval( function() {

      now = new Date().getTime();

      distance = countDownDate - now;

      let days = Math.floor( distance / ( 1000 * 60 * 60 * 24 ) );
      let hours = Math.floor( ( distance % ( 1000 * 60 * 60 * 24 ) ) / ( 1000 * 60 * 60 ) );
      let minutes = Math.floor( ( distance % ( 1000 * 60 * 60 ) ) / ( 1000 * 60 ) );
      let seconds = Math.floor( ( distance % ( 1000 * 60 ) ) / 1000 );

      if ( days < 10 ) {
        days = "0" + days;
      }
      if ( hours < 10 ) {
        hours = "0" + hours;
      }
      if ( minutes < 10 ) {
        minutes = "0" + minutes;
      }
      if ( seconds < 10 ) {
        seconds = "0" + seconds;
      }

      // Display the result in the element
      document.querySelector( "[data-display-countdown]" ).innerHTML = days + ":" + hours + ":" +
        minutes + ":" + seconds;

      if ( distance <= 0 ) {
        clearInterval( x );
        document.querySelector( "[data-display-countdown]" ).innerHTML = "00:00:00:00";

        $( "[data-display-stream-head-public]" ).show();
        $( "[data-display-stream-head-waiting]" ).hide();
      }
    }, 1000 );
  }

} );
