import "./vendor/pasley.min";

$( function() {

  window.Parsley.on( "form:validate", function() {

    // This global callback will be called for any field that fails validation.

    let parsleyError = $( ".parsley-error" ),
      parsleyErrorLength = parsleyError.length;

    if ( parsleyErrorLength > 0 ) {
      let destination = parsleyError.offset().top - 10;
      $( "html" ).animate( { scrollTop: destination }, 1000 );
    }
  } );

  const $form = $( ".new_volunteer_application" );

    if ( $form.length > 0 ) {
      const $form_instance = $form.parsley();
      const $birthdayOptions = $( ".birthday" );
      const $birthYear = $( "#volunteer_application_birthdate_year" );
      const $birthMonth = $( "#volunteer_application_birthdate_month" );
      const $birthDay = $( "#volunteer_application_birthdate_day" );
      const $birthdayValidateBox = $( ".birthday-validate-error" );
      const currentDate = new Date();
      const accessDate = currentDate.setFullYear( currentDate.getFullYear() - 18 );


      $birthdayOptions.on( "change", function( e ) {
        validateBirthdateField( $form_instance );
      } );

      const validateBirthday = function () {
        let monthNo = parseInt( $birthMonth.val(), 10 );
        monthNo = `${monthNo > 0 ? monthNo - 1 : monthNo}`;

        let formattedBirthMonth = monthNo.length < 2 ? 0 + monthNo : monthNo;
        let formattedBirthDay = $birthDay.val().length < 2 ? 0 + $birthDay.val() : $birthDay.val();
        let formattedYear = $birthYear.val().length < 4 ? "0" : $birthYear.val();
        const birthdayString = formattedBirthDay + "-" + formattedBirthMonth + "-" + formattedYear;
        let birthdayStringConverted = convertDateForIos( birthdayString );
        if ( Date.parse( birthdayStringConverted ) < accessDate ) {
          return true;
        } else {
          return false;
        }
      };


      $form.parsley().on( "form:validate", function( formInstance ) {
        validateBirthdateField( formInstance );
      } );

      function convertDateForIos( date ) {
        let arr = date.split( /[- :]/ );
        if ( arr[ 2 ] === "0" || arr[ 1 ] === "0" || arr[ 0 ] === "0" ) {
          return NaN;
        } else {
          date = new Date( arr[ 2 ], arr[ 1 ], arr[ 0 ] );
          return date;
        }
      }

      function validateBirthdateField(formInstance) {
        if ($birthDay.val() == undefined) { return }

        if ( validateBirthday() ) {
          $birthdayValidateBox.parsley().removeError( "custom-error-message", {} );
          $birthdayValidateBox.parsley().removeError( "custom-error-message-black", {} );
          $birthdayValidateBox.addClass( "parsley-success" );
          formInstance.validationResult = true;
        } else {
          $birthdayValidateBox.parsley().removeError( "custom-error-message", {} );
          $birthdayValidateBox.parsley().removeError( "custom-error-message-black", {} );
          $birthdayValidateBox.removeClass( "parsley-success" );
          $birthdayValidateBox.parsley().addError( "custom-error-message", { message: "*Must be 18+ to volunteer with MOC." } );
          formInstance.validationResult = false;
        }
      }
    }

} );
