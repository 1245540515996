import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = [
    'openSetListCollapse',
    'SongsBlock'
  ]

  connect() {
    this.setupCollapseListener()
  }

  setupCollapseListener() {
    const collapseElement = document.querySelector('#collapseCreateSetList')

    // Handle both show and hide events
    $(collapseElement).on('show.bs.collapse', () => {
      this.SongsBlockTarget.classList.add('col-lg-7','col-xl-8')
    })

    $(collapseElement).on('hide.bs.collapse', () => {
      this.SongsBlockTarget.classList.remove('col-lg-7','col-xl-8')
    })
  }

  showCollapse() {
    const collapseElement = document.querySelector('#collapseCreateSetList')
    $(collapseElement).collapse('show')
    this.SongsBlockTarget.classList.add('col-lg-7','col-xl-8')
  }
}