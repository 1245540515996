$( function() {

  $( document ).on( "click", ".stf-video__poster", function( ev ) {
    ev.preventDefault();
    let $poster = $( this );
    let $wrapper = $poster.closest( ".stf-video__inner" );
    videoPlay( $wrapper );
  } );

  function videoPlay( $wrapper ) {
    let $iframe = $wrapper.find( ".stf-video__iframe" );
    let src = $iframe.data( "src" );
    $wrapper.addClass( "active" );
    $iframe.attr( "src", src );
  }

} );

