$( document ).ready( function() {
  let $finishApplicationLink = $( "[data-finish-application-in]" );

  if ( $finishApplicationLink.length > 0 ) {
    let timeout = $finishApplicationLink.data().finishApplicationIn;

    setTimeout( function() {
      $finishApplicationLink[ 0 ].click();
    }, timeout );
  }
} );
