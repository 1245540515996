import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "dayButton"];

  connect() {
    this.updateDayButtonStyles();
  }

  checkDay(event) {
    this.updateDayButtonStyles();
  }

  updateDayButtonStyles() {
    this.checkboxTargets.forEach((checkbox) => {
      const day = checkbox.dataset.day;
      const dayButton = document.querySelector(`[data-day='${day}']`);
      const allCheckboxes = this.checkboxTargets.filter(cb => cb.dataset.day === day);
      const anyChecked = allCheckboxes.some(cb => cb.checked);

      if (dayButton) {
        dayButton.classList.toggle('btn-success', anyChecked);
        dayButton.classList.toggle('btn-outline-dark', !anyChecked);
      }
    });
  }
}
