import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: '.sortable-handle', // handle's class
      animation: 150,
      onStart: (_evt) => {
        try {
          // Hide all tooltips on the page
          $('.sortable-handle').tooltip('dispose')
          $('.tooltip').remove()
          // Add grabbing class
          $('.sortable-handle').addClass('is-dragging')
        } catch (e) {
          console.error("Error with tooltips:", e)
        }
      },
      onEnd: (evt) => {
        try {
          const handles = $('.sortable-handle')
          handles.tooltip({ placement: 'top' })
          // Remove grabbing class
          handles.removeClass('is-dragging')
        } catch (e) {
          console.error("Error with tooltips:", e)
        }
        this.end(evt)
      }
    })
  }

  end(event) {
    const data = new FormData()
    data.append("position", event.newIndex + 1)
    data.append("item_id", event.item.dataset.itemId)

    Rails.ajax({
      url: this.data.get("url"),
      type: "PATCH",
      data,
      error: () => {
        window.notifier.alert('There was an error while sorting the songs. Please try again.');
      }
    })
  }
}