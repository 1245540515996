$( document ).ready( function() {

  $( "[data-button-leave-feedback]" ).on( "click", function( e ) {
    let shiftId = $( this ).data( "shift-id" );
    $( "[data-input-feedback-shift-id]" ).val( shiftId );
    let programId = $( this ).data( "program-id" );
    $( "[data-input-feedback-program-id]" ).val( programId );
  } );

  $( "[data-checkbox-technical-issues]" ).click( function() {
    $( "[data-checkbox-technical-issues]" ).not( this ).prop( "checked", false );

    if ( !$( this ).prop( "checked" ) ) {
      $( this ).prop( "checked", true );
    }
  });


  function showErrorMessage(messageEl) {
    var message = "There was an error. Please try again later.";
    messageEl.text(message);
    messageEl.removeClass("alert-warning alert-success p-0").addClass("alert-danger p-3");
  }

  function showSuccessMessage(messageEl) {
    var message = "Updated successfully!";
    messageEl.text(message);
    messageEl.removeClass("alert-warning alert-danger p-0").addClass("alert-success p-3");
  }


  function showSubmitMessage(messageEl) {
    var message = "Updating...";
    messageEl.text(message);
    messageEl.removeClass("alert-success alert-danger p-0").addClass("alert-warning p-3");
  }


  function updateSurveyProgram() {
    var form = $(this).closest('form');
    var select = $(this);
    var messageSelector = '[data-survey-program-select-update-message]';
    var messageEl = $(messageSelector);
    var formData = form.serializeArray();
    
    showSubmitMessage(messageEl);
    select.prop("disabled", true);

    $.ajax({
      type: form.attr('method'),
      url: form.attr('action'),
      data: formData,
      success: function(response) {
        showSuccessMessage(messageEl);
        select.prop("disabled", false);
      },
      error: function(response) {
        showErrorMessage(messageEl);
        select.prop("disabled", false);
      }
    });
  }

  function setupSurveyProgramForm() {
    if ($('[data-survey-program-select]').length) {
      document.querySelector('[data-survey-program-select]').onchange = updateSurveyProgram
    } 
    if ($('[data-survey-review-select]').length) {
      document.querySelector('[data-survey-review-select]').onchange = updateSurveyProgram
    }
  }

  setupSurveyProgramForm();

  $( "[data-submit-feedback]" ).on( "click", function( e ) {
    e.preventDefault();

    let $form = $( "[data-feedback-form]" );
    let params = Object.fromEntries( new URLSearchParams( $form.serialize() ) );
    let path = $form.attr( "action" );

    console.log( "params", params );

    $.ajax( {
      dataType: "json",
      method: "POST",
      url: path,
      data: params,
      success: function( data ) {
        window.location.href = data.redirect_path;
      },
      error: function( data ) {
        console.log( data );
        let response = JSON.parse( data.responseText );
        let items = "";

        $.each( response.errors, function( index, error ) {
          items += `<li>${error}</li>`;
        } );

        $('[data-show-errors]').removeClass("d-none");
        $( "[data-show-errors]" ).html( `
          <h4>${response.errors.length} errors prohibited this feedback from being saved:</h4>
          <ul>${items}</ul>
        ` );

        $( "[data-button-leave-feedback]" ).animate( { scrollTop: 0 }, "slow" );
      }
    } );
  } );
} );
