$(document).ready(function () {

  if ($('.related-volunteer-checkbox').length) {
    $('.related-volunteer-checkbox:checkbox:checked').each(function () {
      let relatedVolunteerId = $(this).data('volunteer-id');
      $(this).addClass('show-label');
      $(this).removeClass('hide-label');
      $('#hiddenSelectedRelatedVolunteers').append(
        '<input type="hidden" class="hiddenRelatedVolunteerInput" id="hiddenRelatedVolunteerInput' +
        relatedVolunteerId +
        '" name="volunteer[related_volunteer_ids][]" value="' +
        relatedVolunteerId +
        '"/>'
      );
    });
  }


  $(document).on('change', '.related-volunteer-checkbox', function() {
    let relatedVolunteerId = $(this).data('volunteer-id');
    let selector = `#selected_related_volunteer_id_${relatedVolunteerId}`;
    if ($(this).is(":checked")) {
      $(selector).addClass('show-label');
      $(selector).removeClass('hide-label');
      $('#hiddenSelectedRelatedVolunteers').append(
        '<input type="hidden" class="hiddenRelatedVolunteerInput" id="hiddenRelatedVolunteerInput' +
        relatedVolunteerId +
        '" name="volunteer[related_volunteer_ids][]" value="' +
        relatedVolunteerId +
        '"/>'
      );
    } else {
      $(selector).removeClass('show-label');
      $(selector).addClass('hide-label');
      $('#hiddenRelatedVolunteerInput' + relatedVolunteerId).remove();
    }
  });

  function init_rv_checkboxes_behavior(){
    $('#related_volunteer_id_all').on('click', function () {
      let all_checked = $('#related_volunteer_id_all').prop('checked');

      if (all_checked)
        $('.related-volunteer-checkbox:checkbox:not(:checked)').trigger('click');
      else
        $('.related-volunteer-checkbox:checkbox:checked').trigger('click');
    });

    $('.related-volunteer-checkbox').on('click', function () {
      if (!$(this).prop('checked'))
        $('#related_volunteer_id_all').prop('checked', false);
    });
  }
  init_rv_checkboxes_behavior();

  // MODAL PAGINATION
  function init_rv_pagination(){
    let relatedVolunteerPagination = $( "[data-related-volunteer-pagination]" );
    if (relatedVolunteerPagination.length > 0) {
      relatedVolunteerPagination.pagination({
        pages: $('#totalPages').data('total-pages'),
        itemsOnPage: 15,
        cssStyle: 'light-theme',
        onPageClick: function (pageNumber, e) {
          $('.related-volunteer-page').attr('hidden', true);
          $(`#related-volunteer-page-${pageNumber}`).attr('hidden', false);
        }
      });
    }
  }
  init_rv_pagination();

  // FILTERS

  function applyRVFilters () {
    let volunteerId = $('.add-related-volunteers-button').data('volunteer-id');
    let volunteerTypeId = $("#addRelatedVolunteer").data('volunteer-type-id');

    let params = Object.fromEntries(new URLSearchParams($('.add-related-volunteer-filter').serialize() + '&volunteer_id=' + volunteerId + '&volunteer_type_id=' + volunteerTypeId));

    $.ajax({
      dataType: "html",
      method: 'GET',
      url: "/available_related_volunteers",
      data: params,
      success: function (data) {
        $('#addRelatedVolunteersList').html(data);
        $.each($('.hiddenRelatedVolunteerInput'), function(){
          $('#volunteer-name-' + $(this).val()).prop('checked', true);
        });
        init_rv_pagination();
        init_rv_checkboxes_behavior();
      },
      error: function (data) {}
    });
  }

  $('#search-rv-filter-modal').on('keyup', function () {
    applyRVFilters();
  });

  $('#branch-rv-filter-modal').on('change', function () {
    applyRVFilters();
  });

  // Add hidden inputs for cached selected checkbox
  $.each($('.related_volunteer_check_box:checkbox:checked'), function(){
    relatedVolunteerId = $(this).val();
    $('#hiddenSelectedRelatedVolunteers').append(
      '<input type="hidden" class="hiddenRelatedVolunteerInput" id="hiddenRelatedVolunteerInput' +
      relatedVolunteerId +
      '" name="volunteer[related_volunteer_ids][]" value="' +
      relatedVolunteerId +
      '"/>'
    );
  });

  $('.profile.add-related-volunteers-button').on('click', function() {
    let titleText = $(this).text().trim()

    $('#addRelatedVolunteer .section-title').text(titleText + ':');
    $('#addRelatedVolunteerSubmit').text(titleText);
    $('#addRelatedVolunteer').data('volunteer-type-id', $(this).data('volunteer-type-id'));
    applyRVFilters();
  });

  $('#addRelatedVolunteerSubmit').on('click', function () {
    let profileId = $('#addRelatedVolunteer').data('profile-id');
    let volunteerTypeId = $('#addRelatedVolunteer').data('volunteer-type-id');
    let relatedVolunteersId = [];
    let sendEmail = $('#addRelatedVolunteerSubmit').data('send-email')

    $('.related-volunteer-checkbox:checkbox:checked').each(function () {
      relatedVolunteersId.push($(this).data('volunteer-id'));
    });

    $.ajax({
      dataType: "json",
      method: 'POST',
      url: "/available_related_volunteers",
      data: {
        volunteer_type_id: volunteerTypeId,
        related_volunteer_ids: relatedVolunteersId,
        volunteer_id: profileId,
        send_email: sendEmail
      },
      success: function (data) {
        update_related_volunteers(data, volunteerTypeId);
      },
      error: function (data) {
        console.log("error");
        console.log(data);
      }
    });
  });

  function update_related_volunteers(data, volunteerTypeId){
    let volunteerContainer = $(`div[data-volunteer-type-id=${volunteerTypeId}]`);
    volunteerContainer.empty();

    data.forEach(function(volunteer){
      volunteerContainer.append(
        `<span class="card px-2 py-1 mr-1 mb-2 id="selected_related_volunteer_id_${volunteer[0]}">${volunteer[1]}</span>`
      );
    });
  }
});
