import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["passwordInput", "showIcon", "hideIcon"]

  toggle() {
    const input = this.passwordInputTarget;
    const showIcon = this.showIconTarget;
    const hideIcon = this.hideIconTarget;
    
    if (input.type === "password") {
      input.type = "text";
      showIcon.classList.add("d-none");
      hideIcon.classList.remove("d-none");
    } else {
      input.type = "password";
      showIcon.classList.remove("d-none");
      hideIcon.classList.add("d-none");
    }
  }
}
