import { Controller } from "@hotwired/stimulus"


const STATUS_CONFIG = {
  deferred: {
    action: "Defer Track",
    statement: "<strong>should be paused on this track due to their request or no response.</strong><br>Volunteer track can be moved back to its previous status as needed.",
    addNotes: "Please add detailed notes on their profile as to why.",
    notifiesVolunteer: false
  },
  waitlisted: {
    action: "Waitlist Track",
    statement: "<strong>should be placed on a waitlist for this track.</strong>",
    addNotes: "Please add detailed notes to their profile.",
    notifiesVolunteer: false
  },
  approved: {
    action: "Change Track to In Training",
    statement: "<strong>is ready to complete the certification process for this track.</strong>",
    addNotes: "A notification email <u>will</u> be sent to the volunteer if this is their first time In Training for this track.",
    notifiesVolunteer: undefined
  },
  rejected: {
    action: "Reject Track",
    statement: "<strong>is not suitable for MOC programs on this track.</strong>",
    addNotes: "Please add detailed notes to their profile as to why.",
    notifiesVolunteer: false
  },
  leave_of_absence: {
    action: "Change Track to Leave of Absence",
    statement: "<strong>has requested a leave of absence for this track.</strong>",
    addNotes: "Please add detailed notes to their profile for their anticipated return.",
    notifiesVolunteer: false
  },
  inactive: {
    action: "Change Track to Inactive",
    statement: "<strong>should no longer be active and able to sign up for shifts on this track.</strong>",
    addNotes: "Please add detailed notes to their profile as to why.",
    notifiesVolunteer: false
  },
  active: {
    action: "Change Track to Active",
    statement: "<strong>has completed certification for this track, any required hospital onboarding, their required shadows and they are ready to sign up for shifts on their own.</strong>",
    addNotes: "A notification email <u>will</u> be sent to the volunteer if this is their first time Active for this track.",
    notifiesVolunteer: undefined
  },
  reactive: {
    action: "Re-Activate Track",
    statement: "<strong>has completed training for this track recently and is ready to return as an active Volunteer without additional training at this time.</strong>",
    addNotes: "Please add any additional notes to their profile.",
    notifiesVolunteer: false
  },
}

export default class extends Controller {
  static targets = [
    "statusSelect",
    "currentStatus",
    "modalTitle",
    "newStatus",
    "newStatusBadge",
    "statusInput",
    "statusSubmit",
    "statusChangeStatements",
    "addNotesInfo",
    "volunteerNotification"
  ]

  submitForm(event) {
    event.preventDefault()
    const newStatus = this.statusSelectTarget.value
    const currentStatus = this.currentStatusTarget.value

    // Remove all classes and add the new ones
    this.newStatusBadgeTarget.className = ''
    this.newStatusBadgeTarget.classList.add('badge', `badge-${newStatus}`)
    this.newStatusBadgeTarget.textContent = this.formatStatus(newStatus)
    this.newStatusTarget.textContent = this.formatStatus(newStatus)
    this.statusInputTarget.value = newStatus

    const statusToUpdate = (currentStatus === 'leave_of_absence' || currentStatus === 'inactive') && newStatus === 'active' ? 'reactive' : newStatus;
    this.updateAdditionalInfo(statusToUpdate)

    const modal = new bootstrap.Modal(this.element.querySelector('.modal-status'))
    modal.show()
  }

  updateAdditionalInfo(status) {
    const config = STATUS_CONFIG[status] || {}
    const {
      action = `Change track to ${this.formatStatus(status)}`,
      statement = '',
      addNotes = '',
      notifiesVolunteer
    } = config

    this.modalTitleTarget.textContent = action
    this.statusSubmitTarget.value = action
    this.statusChangeStatementsTarget.innerHTML = statement
    
    if (addNotes) {
      this.addNotesInfoTarget.querySelector('i').innerHTML = addNotes
      this.addNotesInfoTarget.classList.remove('d-none')
    } else {
      this.addNotesInfoTarget.classList.add('d-none')
    }
    
    this.updateVolunteerNotification(notifiesVolunteer)
  }


  updateVolunteerNotification(willNotify) {
    const willElement = this.volunteerNotificationTarget.querySelector('[data-notification="will"]')
    const wontElement = this.volunteerNotificationTarget.querySelector('[data-notification="wont"]')

    willElement.classList.add('d-none')
    wontElement.classList.add('d-none')

    if (willNotify !== undefined) {
      willNotify ? willElement.classList.remove('d-none') : wontElement.classList.remove('d-none');
    }
  }

  formatStatus(status) {
    switch (status) {
      case 'awaiting_for_review':
        return 'To Review'
      case 'approved':
        return 'In Training'
      default:
        return status.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())
    }
  }
}
