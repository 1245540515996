import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'
import 'slim-select/dist/slimselect.css'

export default class extends Controller {
  static targets = ['field']
  static values = {
    settings: Object
  }

  connect() {
    const defaultSettings = {
      closeOnSelect: false
    }

    const dataSettings = this.parseDataSettings()
    const settings = { ...defaultSettings, ...dataSettings, ...this.settingsValue }

    this.slimSelect = new SlimSelect({
      select: this.fieldTarget,
      settings: { ...settings },
      events: {
        beforeChange: this.handleBeforeChange.bind(this)
      }
    })
  }

  parseDataSettings() {
    const dataSettings = this.fieldTarget.dataset.slimSettings
    if (dataSettings) {
      try {
        const parsedSettings = JSON.parse(dataSettings)
        return parsedSettings
      } catch (error) {
      }
    }
    return {}
  }

  handleBeforeChange(newVal, oldVal) {
    if (!this.fieldTarget.multiple) {
      return true;
    }

    const allOption = newVal.find(option => option.value === 'all');
    const oldAllOption = oldVal.find(option => option.value === 'all');

    let selectedValues;

    if (!oldAllOption && allOption) {
      selectedValues = ['all'];
    } else if (oldAllOption && allOption && newVal.length > 1) {
      selectedValues = newVal.filter(option => option.value !== 'all').map(option => option.value);
    } else if (newVal.length === 0) {
      selectedValues = ['all'];
    } else {
      selectedValues = newVal.map(option => option.value);
    }

    this.slimSelect.setSelected(selectedValues, false);

    return false;
  }
}
