$(document).ready(function () {
  if (document.getElementById("search_by") && document.getElementById("spinner")) {
    document.getElementById("search_by").addEventListener("input", function () {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        let form = this.closest("form");
        document.getElementById("spinner").classList.remove('d-none')

        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set('search_by', this.value);
        let updatedUrl = window.location.pathname + '?' + searchParams.toString();
        window.history.replaceState({ path: updatedUrl }, '', updatedUrl);

        $.ajax({
          dataType: "script",
          type: form.method,
          url: form.action,
          data: $(form).serialize(),
          success: function(response) {
            document.getElementById("spinner").classList.add('d-none')
          },
          error: function(error) {
            document.getElementById("spinner").classList.add('d-none')
          }
        });
      }, 350);
    });
  }
});
