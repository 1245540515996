import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["otherGenreField", "otherGenreInput"];

  connect() {
    this.otherGenreField = document.getElementById("other-genre-field");
    this.otherGenreInput = this.otherGenreField.querySelector('input');
    this.checkInitialToggle();
  }

  checkInitialToggle() {
    const checkboxes = this.element.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach(checkbox => this.toggleDisplay(checkbox));
  }

  toggleOther(event) {
    this.toggleDisplay(event.target);
  }

  toggleDisplay(checkbox) {
    const label = this.element.querySelector(`label[for="${checkbox.id}"]`);
    if (label?.innerText === "Other") {
      this.otherGenreField.classList.toggle('d-none', !checkbox.checked);
      if (!checkbox.checked) {
        this.otherGenreInput.value = '';
      }
    }
  }
}
