$(document).ready(function() {
  // Function to check if the referred_by field should be shown or hidden
  function checkReferredByVisibility() {
    var selectedValues = [];

    
    if (!document.querySelector('input[name="hospital_application[how_did_hear_about_moc][]"]')) { return; }

    $('input[name="hospital_application[how_did_hear_about_moc][]"]:checked').each(function() {
      selectedValues.push($(this).val());
    });

    // Show or hide the referred_by field based on the selected values
    if (selectedValues.includes('Other') || selectedValues.includes('Referral or Healthcare Colleague')) {
      document.getElementById('hospital_referred_by_field').classList.remove('hide-field')
    } else {
      document.getElementById('hospital_referred_by_field').classList.add('hide-field')
    }
  }

  // Call the function initially on page load
  checkReferredByVisibility();

  // Bind the function to the change event of the checkboxes
  $('input[name="hospital_application[how_did_hear_about_moc][]"]').change(function() {
    checkReferredByVisibility();
  });
});
