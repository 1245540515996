import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "confirmCheckbox", "virtualTrackCheckbox", "submitButton"];

  connect() {
    this.toggleSubmitButton();
  }

  toggleSubmitButton() {
    let virtualTrackSelected = false;
    this.virtualTrackCheckboxTargets.forEach(checkbox => {
      if (checkbox.checked) {
        virtualTrackSelected = true;
      }
    });

    if (virtualTrackSelected && !this.confirmCheckboxTarget.checked) {
      this.submitButtonTarget.disabled = true;
    } else {
      this.submitButtonTarget.disabled = false;
    }
  }
}
