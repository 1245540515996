$( document ).ready( function() {
  $("[data-button-submit-branch]").on( "click", function( e ) {
    e.preventDefault();

    let params = $( "[data-form-new-branch]" ).serializeArray();

    $.ajax( {
      dataType: "json",
      method: "POST",
      url: "/program_team/branches",
      data: params,
      success: function( data ) {
        window.location.href = data.redirect_url;
      },
      error: function( data ) {
        console.log( data );
        let response = JSON.parse( data.responseText );
        let items = "";

        $.each( response.errors, function( index, error ) {
          items += `<li>${error}</li>`;
        } );

        $( "[data-display-errors]" ).html( `
          <h4>${response.errors.length} errors prohibited this volunteer from being saved:</h4>
          <ul>${items}</ul>
        ` );

        $( "[data-modal-new-volunteer]" ).animate( { scrollTop: 0 }, "slow" );
      }
    } );
  } );
} );
