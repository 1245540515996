$( function() {

  $( "body" ).on( "click", "[data-display-accordion-control]", function( e ) {
    e.preventDefault();
    _this = $( this );

    // _this.toggleClass('hide-accordion');
    _this.find( "[data-icon-accordion-arrow]" ).toggleClass( "rotate" );
    _this.next( "[data-display-accordion-panel]" ).not( ":animated" ).slideToggle( "fast" );
  } );

} );