import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tagsSelect", "statusSelect", "typeSelect", "branchSelect"]

  connect() {
    this.setupEventListeners()
  }

  disconnect() {
    this.removeEventListeners()
  }

  setupEventListeners() {
    this.targetElements.forEach(target => {
      target.addEventListener('change', this.handleFilterChange.bind(this))
    })
  }

  removeEventListeners() {
    this.targetElements.forEach(target => {
      target.removeEventListener('change', this.handleFilterChange.bind(this))
    })
  }

  handleFilterChange(event) {
    this.debounceSubmit()
  }

  debounceSubmit() {
    clearTimeout(this.debounceTimer)
    this.debounceTimer = setTimeout(() => this.submit(), 300)
  }

  submit() {
    const form = this.element.closest('form')
    if (!form) {
      return
    }

    const formData = new FormData(form)
    const url = new URL(window.location.href)
    const params = new URLSearchParams()

    url.searchParams.forEach((value, key) => {
      params.delete(key)
    })

    for (let [key, value] of formData.entries()) {
      if (value !== "") {
        if (key.endsWith('[]')) {
          params.append(key, value)
        } else {
          params.set(key, value)
        }
      }
    }

    url.searchParams.forEach((value, key) => {
      if (!params.has(key) && key !== 'page') {
        params.append(key, value)
      }
    })

    url.search = params.toString()

    history.pushState({}, "", url)

    $.ajax({
      url: url.toString(),
      method: "GET",
      dataType: "script",
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      },
      success: function(response) {
        // The response is a JS template, which will be automatically executed
      },
      error: function(xhr, status, error) {
        // Handle error if needed
      }
    })
  }

  get targetElements() {
    return this.constructor.targets.flatMap(targetName => 
      this[`${targetName}Targets`] || []
    )
  }
}
