// toggle visibility on social media inputs when entering page
$( document ).ready( function() {
  $("[data-social-trigger]").each(function( index ) {
    if ( $(this).is(":checked") ){
      let triggerValue = $(this).val();
      let socialMedia = $(this).data("social-trigger");
      if ( triggerValue === "true" ) {
        $( "[data-social-fields="+socialMedia+"]" ).removeClass( "hide-field" );
      } else {
        $( "[data-social-fields="+socialMedia+"]" ).addClass( "hide-field" );
      }
    }
  });
});

// toggle visibility on social media inputs on change
$( document ).on( "change", "[data-social-trigger]", function( e ) {
  let triggerValue = $(this).val();
  let socialMedia = $(this).data('social-trigger');

  if ( triggerValue === "true" ) {
    $( "[data-social-fields="+socialMedia+"]" ).removeClass( "hide-field" );
  } else {
    $( "[data-social-fields="+socialMedia+"]" ).addClass( "hide-field" );
  }
});

// check if empty values on social YES fields before submitting
$(document).on("click", "[data-social-submit]", function (e) {
  $('[data-social-trigger]').each(function(){
    if ($(this).is(':checked')){
      let triggerValue = $(this).val();
      if ( triggerValue === "true"){
        let socialMedia = $(this).data('social-trigger');
        let inputValue = $("#volunteer_application_"+socialMedia).val();
        if (!inputValue){
          $("[data-social-warning="+socialMedia+"]").removeClass('d-none');
          e.preventDefault();
        }
      }
    }  
  });  
});
