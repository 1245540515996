$(document).ready(function () {
  if (document.getElementById("tags_search") && document.getElementById("spinner")) {
    document.getElementById("tags_search").onchange = function () {
      let form = this.closest("form");
      document.getElementById("spinner").classList.remove('d-none')

      $.ajax({
        dataType: "script",
        type: form.method,
        url: form.action,
        data: $(form).serialize(),
        success: function (response) {
          document.getElementById("spinner").classList.add('d-none')
        },
        error: function(error) {
          document.getElementById("spinner").classList.add('d-none')
        }
      });
    }
  }
});
