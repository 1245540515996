// CANCEL SHIFT BY VOLUNTEER

$( document ).ready( function() {

  $( "[data-program-id]" ).on( "click", function( e ) {
    let programId = $( this ).data( "program-id" );
    let shiftId = $( this ).data( "shift-id" );

    $.ajax( {
      dataType: "json",
      method: "GET",
      url: `/program_team/programs/${programId}/shifts/${shiftId}`,
      success: function( data ) {
        $( "[data-modal-cancel-shift]" ).empty();
        $( "[data-modal-cancel-shift]" ).html( `

          <div class="col-12 col-lg-7">
            <div class="card flex-grow-1">
              <div class="card-header py-2 d-flex align-items-center ${data.in_person ? "bg-primary" : "bg-info-dark"}">
                <div class="d-flex w-100">
                  <svg class="icon icon-size-4 text-contrast-bg mb-0 ml-n1 mr-2 flex-shrink-0">
                    <use xlink:href="/images/symbol-defs.svg#icon-${data.in_person ? "bedside" : "virtual"}"></use>
                  </svg>
                  <p class="w-100 text-white">${ data.title }</p>
                </div>
              </div>
              <div class="row mx-n2 flex-grow-1">
                <div class="col-12 col-sm-2 col-md-3 col-xl-3 px-2">
                  <div class="date-block h-100">
                    <div class="date-block__week bg-secondary">
                      ${ data.weekday }
                    </div>
                    <div class="date-block__date">
                      <p class="d-sm-none">${ data.full_time } ${ data.local_timezone_abbr }</p>
                      <p class="d-none d-sm-block">${ data.month }</p>
                      <p class="d-none d-sm-block date-block__date__day">
                        ${ data.day }
                      </p>
                    </div>
                    <div class="date-block__time ${data.in_person ? "bg-moc-pink" : "bg-info-pale"}">
                      ${ data.time } ${ data.local_timezone_abbr }
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-10 col-md-9 col-xl-9 px-sm-2 pb-2">
                  <div class="text-small font-weight-bold bg-grey-pale py-1 ml-sm-n3 pl-3">ID ${ data.id }</div>
                  <div>
                    <div class="d-flex align-items-center flex-wrap my-2">
                      <svg class="icon icon-size-4 flex-shrink-0 mr-1">
                        <use xlink:href="/images/symbol-defs.svg#icon-guide"></use>
                      </svg>
                      <span>${data.guide || "TBD"}</span>
                    </div>
                    <div class="d-flex align-items-center flex-wrap mb-2">
                      <svg class="icon icon-size-4 flex-shrink-0 mr-1">
                        <use xlink:href="/images/symbol-defs.svg#icon-musician"></use>
                      </svg>
                      <span>${data.musician || "TBD"}</span>
                    </div>
                  </div>
                  <hr/>
                  <div>
                    <strong>MOC Program Contact</strong>
                    <p>${ data.program_manager_name }</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-7 my-2">
            <div class="cancellation-reason-block">
              <strong>Cancellation Reason</strong>
              <select class="form-control js-select2 cancellation-reason" name="cancellation_reason" data-cancellation-reason id="cancellation_reason">
                <option value="moc">MOC Canceled</option>
                <option value="hospital">Hospital Canceled</option>
                <option value="external">External Cancellation</option>
              </select>
            </div>
          </div> 
          <div class="col-12 col-lg-7 mt-4">
            <div class="d-flex justify-content-between">
              <a href="#" class="btn btn-dark full-width" data-confirm="" data-dismiss="modal">No, Don't Cancel</a>
              <a href="#" class="btn btn-outline-danger full-width" id="confirmCancelShiftPT" data-shift-id="${ data.id }" data-confirm-cancel-shift="" data-dismiss="modal" data-target="#cancelShiftPTResult">Yes, Cancel This Shift</a>
            </div>
          </div>
        ` );
      },
      error: function( data ) {
        console.log( "error" );
        console.log( data );
      }
    } );
  } );

  $( document ).on( "click", "[data-confirm-cancel-shift]", function() {
    let shiftId = $( this ).data( "shift-id" );
    let reason = $( "[data-cancellation-reason]" ).val();

    $.ajax( {
      dataType: "json",
      method: "POST",
      url: "/program_team/cancel_shifts",
      data: { shift_id: shiftId, cancellation_reason: reason },
      success: function( data ) {
        console.log( "success" ); //REMOVE

        $( "[data-cancel-shift-result-text]" ).empty();
        $( "[data-cancel-shift-result-text]" ).html( `Your shift at ${ data.title } on ${ data.full_time } has successfully been cancelled` );

        $( "[data-modal-cancel-shift-result]" ).modal().show();
      },
      error: function( data ) {
        console.log( "error" ); //REMOVE
        console.log( data ); //REMOVE
      }
    } );
  } );

  $( "[data-modal-cancel-shift-result]" ).on( "hidden.bs.modal", function() {
    window.location.reload();
  } );

} );
