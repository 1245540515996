import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'modal',
    'form',
    'setListName',
    'setListNameInput',
    'setListNameWarning',
    'submitCopyButton',
    'selectedSongsList',
    'selectedSongsCount'
  ]

  static values = {
    setListsNames: Array
  }

  connect() {
    this.setupModalListener()
  }

  setupModalListener() {
    const modal = this.modalTarget
    $(modal).on('show.bs.modal', this.handleModalShow.bind(this))
  }

  /**
   * triggered modal is opened to update song list
   */
  handleModalShow() {
    this.updateSelectedSongsList()
  }

  /**
   * triggered when the form submit button is clicked
   */
  submitForm() {
    const $form = $(this.formTarget)
    this.appendSongsIdsToForm($form)
    
    $form.trigger('submit.rails')
  }

  /**
   * triggered when the set list select is changed
   */
  setListSelectChanged(event) {
    // If a set list is selected, hide the set list name input and enable the submit button
    // Otherwise, show the set list name input and disable the submit button if the set list name input is empty
    if (event.target.value) {
      this.setListNameTarget.classList.add('d-none')
      this.submitCopyButtonTarget.removeAttribute('disabled')
    } else {
      this.setListNameTarget.classList.remove('d-none')
      if (!this.setListNameInputTarget.value) {
        this.submitCopyButtonTarget.setAttribute('disabled', 'disabled')
      }
    }
  }

  /**
   * triggered when the set list name input is changed
   */
  setListNameInputChanged(event) {
    // If the set list name input is empty, disable the submit button, enable otherwise
    if (event.target.value) {
      if (this.setListNameExists(this.setListNameInputTarget)) {
        this.submitCopyButtonTarget.setAttribute('disabled', 'disabled')
        this.setListNameWarningTarget.classList.remove('d-none')
      } else {
        this.submitCopyButtonTarget.removeAttribute('disabled')
        this.setListNameWarningTarget.classList.add('d-none')
      }
    } else {
      this.submitCopyButtonTarget.setAttribute('disabled', 'disabled')
      this.setListNameWarningTarget.classList.add('d-none')
    }
  }

  /**
   * Appends the selected songs ids to the form
   */
  appendSongsIdsToForm = ($form) => {
    const songTableController = this.application.getControllerForElementAndIdentifier(document.querySelector('[data-controller="song-table"]'), 'song-table')
    const selectedSongs = songTableController.rowsCheckedValue.map(song => song.id)

    $form.find('[name="songs[]"]').remove()
    selectedSongs.forEach(selectedSong => {
      $form.append(`<input type="hidden" name="songs[]" value="${selectedSong}">`)
    })
  }

  /**
   * Updates the selected songs list in the HTML
   */
  updateSelectedSongsList() {
    const songTableController = this.application.getControllerForElementAndIdentifier(document.querySelector('[data-controller="song-table"]'), 'song-table')
    const selectedSongs = songTableController.rowsCheckedValue
    const selectedSongsCount = selectedSongs.length

    /** show selected songs count as trigger of collpased songs list, will be open if songs <6 */
    if (selectedSongsCount < 6){
      if (selectedSongsCount == 1){
        this.selectedSongsCountTarget.textContent = `${selectedSongsCount} song`
      } else {
        this.selectedSongsCountTarget.textContent = `${selectedSongsCount} songs`
      }
      this.selectedSongsListTarget.classList.add('show')
    } else {
      this.selectedSongsCountTarget.textContent = `these ${selectedSongsCount} songs`
      this.selectedSongsListTarget.classList.remove('show')
    }
    /** appends selcted songs list for volunteer visibility */
    this.selectedSongsListTarget.innerHTML = selectedSongs.map(song => `<li><strong>${song.title}</strong> by ${song.artist}</li>`).join('')
  }

    /**
   * Check if the set list select input value already exists in the setListName values
   */
  setListNameExists(input) {
    return this.setListsNamesValue.some((name) => name.toLowerCase() === input.value.toLowerCase())
  }
}